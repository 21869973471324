import React,{useState,useEffect} from "react";
import {Form, message,Button,Tooltip,Row,Card} from 'antd';
import MySaving from "./../components/Assets/robot.png";
import MyRetirement from "./../components/Assets/beach-chair.png";
import MyTravel from "./../components/Assets/beach.png";
import MyHouse from "./../components/Assets/buy-a-house.png";
import MyTecnology from "./../components/Assets/buy-technolology.png";
import MyCar from "./../components/Assets/buy-a-car.png";
import MyTermGrowth from "./../components/Assets/longterm.png";
import MyChildren from "./../components/Assets/playtime.png";
import MyHealthcare from "./../components/Assets/healthcare.png";
import MyZen from "./../components/Assets/meditation.png";
import MyPlus from "./../components/Assets/plus-sign.png";
import MyMark from "./../components/Assets/exclamation-mark.png";
import MyCheck from "./../components/Assets/check.png";
import MyCross from "./../components/Assets/cross.png";

import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { ArrowDownOutlined, ArrowUpOutlined, CheckCircleFilled, DownOutlined, DownloadOutlined, EditOutlined, ExclamationCircleFilled, HddOutlined, MinusOutlined, PieChartOutlined, PlusOutlined, ReloadOutlined, SettingOutlined, StockOutlined, TableOutlined, UnorderedListOutlined, UploadOutlined } from "@ant-design/icons";

import moment from 'moment';

import  Chart  from "react-apexcharts";

const MySavings = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [filter, setFilter] = useState('open'); // all, open, closed
  
  const user = JSON.parse(localStorage.getItem('user'))
  const user_currency = user.currency
  const investor = JSON.parse(localStorage.getItem('investorProfile'))
  const currencySymbol = user_currency === 'USD' ? '$' : '€';

  const { Meta } = Card;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  //Fields from Graphs to View
  const [allInvestmentTypeWeights, setAllInvestmentTypeWeights] = useState(null);
  const [allAllInvestmentIsProtectedWeights, setAllInvestmentIsProtectedWeights] = useState({});

  const [allSavingStats, setAllSavingStats] = useState([]);
  const [allUserSavingStats, setAllUserSavingStats] = useState([]);
  const [allSavingPerformances, setSavingPerformances] = useState([]);
  const [allBalanceStats, setAllBalanceStats] = useState([]);
  
  const currentDate = new Date();
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
  };

  const formattedDate = currentDate.toLocaleDateString('sv-SE', options);

  useEffect(() => {
    const getUserSavingStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/savings/get-savings', {
          userid:user._id,
          status: false
        },

        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllUserSavingStats(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    getUserSavingStats();
  },[])


  useEffect(() => {
    const getallSavingStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/savingbalances/get-allsavingbalance', {
          userid:user._id,
          filterOption:"all",
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllSavingStats(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    getallSavingStats();
  },[])

    //Analytics
    const totalTurnover = allSavingStats
    .reduce((acc, transaction) => acc + transaction.diffAccum, 0);
  
    const isPositive = totalTurnover >= 0;
    const formattedTurnover = totalTurnover.toLocaleString('de-DE', { style: 'currency', currency: "USD" });

    const totalTurnoverDaily = allSavingStats
    .reduce((acc, transaction) => acc + transaction.diffDaily, 0);
  
    const isPositiveDaily = totalTurnoverDaily >= 0;
    const formattedTurnoverDaily = totalTurnoverDaily.toLocaleString('de-DE', { style: 'currency', currency: "USD" });

  const [chartData, setChartData]= useState([]);
  useEffect(() => {
  const getAllSavingPerformances = async (values) => {
    try{
      const user = JSON.parse(localStorage.getItem('user'))
      setLoading(true);
      const res = await axios.post('/savingperformances/get-allsaving-performance', {
        userid: user._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }       
      );
      setLoading(false);
      setSavingPerformances(res.data);
      setChartData({
        options:{
        chart:{
          id: "basic-bar",
          type: 'area',
          stacked: false,
        },
        xaxis:{
          categories: res.data
          // Sort the data by 'dateSavingPerformance'
          .sort((a, b) => new Date(a.dateSavingPerformance) - new Date(b.dateSavingPerformance))
          // Map the sorted data to categories
          .map(item => {
            // Parse date string to Date object
            const date = new Date(item?.dateSavingPerformance);
            // Format date as YYYY-MM-DD
            return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
          })
        },
        stroke: {
          curve: "straight",
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        },
        series:[
        {
          name: "Return USDT",
          data: res.data.map(item=> parseFloat(item?.today_value).toFixed(2)),
        },
        ],
      },);
      // console.log(res.data);
    }catch (error){
      setLoading(false);
      //message.error('Fetch Issue With account');
    }
  };  
  
  getAllSavingPerformances();
},[])

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap', // Allow items to wrap on small screens
  },
  h4: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px', // Add margin for spacing
  },
  // Media queries for responsive design
  '@media (max-width: 600px)': {
    container: {
      flexDirection: 'column',
      alignItems: 'flex-start', // Align items to the start on small screens
    },
    h4: {
      width: '100%', // Make each h4 take full width
      justifyContent: 'space-between',
    },
  },
};

  /*************************************************************************** */
  const [weightAssetSubject, setWeightAssetSubject]= useState([]);
  const [weightAssetMarks, setWeightAssetMarks]= useState([]);

  useEffect(() => {

  const sSubject=[];
  const sMarks=[];
  const getAllAssetWeights = async (values) => {
    try{
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'))
    const res = await axios.post('/savingbalances/get-allsavingbalance',
    {
      userid:user._id,
      filterOption:"open",
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    setAllBalanceStats(res.data);
    // console.log(res.data);

    for(let i=0; i< res.data.length; i++)
    {
    sSubject.push(res.data[i].savingName.savingName);
    sMarks.push(res.data[i].today_value);
    }
    setWeightAssetSubject(sSubject);
    setWeightAssetMarks(sMarks);
    }catch (error){
    setLoading(false);
    // message.error('Fetch Issue With Sector');
    message.error('You are not Admin User');
    }
  };  
  getAllAssetWeights();
  },[])

  let labelInvestorProfile;
  if (user.investorProfile==="null" && investor===null) {
    labelInvestorProfile = "Ainda não preencheu o questionário de perfil de investidor.";
  }
  else if (investor!==null){
    labelInvestorProfile = 
    <div className="accountpage">
      <div></div>
      <div>
      <h3>Your risk is: {investor.investorProfile}</h3>
      </div>
      <div></div>
    </div>;
  }
  else{
    labelInvestorProfile = 
      <div className="accountpage">
        <div></div>
        <div>
        <h3>Your risk is: {user.investorProfile}</h3>
        </div>
        <div></div>
      </div>;
  }

  // console.log("user.investorProfile: ",user.investorProfile);
  // console.log("investor: ",investor);

    if((user.investorProfile===null && investor===null) || (user.investorProfile==="null" && investor===null)){
    //if(user.investorProfile==="null"){
    return (
      <Layout>
        {loading && <Spinner/>}
      {/* Start Header */}
      {/* <div className="accountpage" style={{marginBottom:"100px"}}>
        <div></div>
        <div><h1>Meus Investimentos</h1></div>
        <div></div>
      </div> */}

      {/* <div className="accountpagebuttonsAll">
            <Button
              shape="round"
              className="btn btn-primary btn-block"
              style={{ background: "green", borderColor: "green" }}
              onClick={() => navigate('/NewSubscriptions')}
            >
              + Nova Subscrição
            </Button>
      </div> */}
      <div>
      </div>
        <div>
          <h3 align="center"><i>To start investing, fill out the questionnaire to identify your investor profile</i></h3>
          <br/>
          <Button
          onClick={() => navigate('/InvestorProfiles')} 
          style={{ width: "100%" ,marginTop:"100px" }}  
          type="primary">
            Start the investor questionnaire now!
          </Button>
        </div>
        <div>
        </div>
        {/* Final Header */}
      </Layout>
    );
  }

  const getSavingNameImage = (categoryName) => {
    switch (categoryName) {
      case "6652420623d1a2622afcff84": //MyRetirement
        return MyRetirement;
      case "6652423c23d1a2622afcff92": //MyChildren
        return MyChildren;
      case "6652421a23d1a2622afcff8b": //MyHouse
        return MyHouse;
      case "665242f123d1a2622afcffd4": //MyCar
        return MyCar;
      case "6659b35eb19a5a32a59b7e5c": //MyTecnology
        return MyTecnology;
      case "6652425c23d1a2622afcff99": //MyTermGrowth
        return MyTermGrowth;
      case "6652427a23d1a2622afcffa0": //MyZen
        return MyZen;
      case "666983edfeb2426ac60808c2": //MyTravel
        return MyTravel;
      case "66698565feb2426ac6080995": //MyHealthcare
        return MyHealthcare; 
      default:
        return MySaving;
    }
  };

    // Function to get the button style based on the filter state
    const getButtonStyle = (type) => {
      return {
        backgroundColor: filter === type ? '#1890ff' : 'transparent', // Change the color when selected
        color: filter === type ? 'white' : 'black', // Change the text color when selected
        marginRight: '10px'
      };
    };

    // Filter function
    const filterCards = () => {
      switch (filter) {
        case 'open':
          return allSavingStats.filter((opts) => !opts.savingName.closePortfolio);
        case 'closed':
          return allSavingStats.filter((opts) => opts.savingName.closePortfolio);
        default:
          return allSavingStats;
      }
    };

    const filteredSavingStats = filterCards();

  return (
    
    <Layout>
      {loading && <Spinner/>}
      {/* Start Header */}


      {labelInvestorProfile}

      {/* <div className="accountpagebuttonsAll">
        <Button
          shape="round"
          className="btn btn-primary btn-block"
          style={{ backgroundColor: "#1890ff" }}
          onClick={() => navigate('/SavingAllTransactions')}
        >
          All Transactions
        </Button>
      </div> */}

      <div className="accountpagebuttonsAll">
        <Button
          shape="round"
          className="btn btn-primary btn-block"
          style={{ background: "green", borderColor: "green" }}
          onClick={() => navigate('/ProcessingRefreshMySavings')}
        >
          Refresh All Portfolios
        </Button>
      </div>
      
      {/* <div><pre>{JSON.stringify(allSavingStats, null, 2) }</pre></div>  */}

      {/* <Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
        <Card
          onClick={() => {
            navigate('/NewSavings');
          }}
          size="small"
          hoverable
          style={{ width: "100%",marginTop: 16,marginRight: "5px",marginLeft: "5px", }}
          cover={(
            <div align="center">
              <br />
              <div>
                <div align="right" style={{ marginRight: "20px" }}>
                  {(() => {
                    return (
                      <div></div>
                    );
                  })()}
                </div>
              </div>
              <div>
                <br />
                <img alt="example" src={MySaving} />
              </div>
              <div>
                <br />
                <h4 className="price">New</h4>
              </div>
            </div>
          )}
        >
          <Meta
            description={(
              <div align="center">
                <h4 className="price">CRYPTO ROBO ADVISOR</h4>
              </div>
            )}
          />
        </Card>
      </Row> */}

      <Form 
        layout="vertical"
        name="PortfolioAggForm"
        style={{marginTop: "20px"}}
        >
        <div style={styles.container}>
        <h4 style={{ 
            ...styles.h4, 
            color: isPositiveDaily ? '#39A939' : '#FF3234',
            fontSize: '18px'
          }}>
          Daily {isPositiveDaily ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
          {formattedTurnoverDaily}
        </h4>
        <h4 style={{ 
            ...styles.h4, 
            color: isPositive ? '#39A939' : '#FF3234',
            fontSize: '18px'
          }}>
          All {isPositive ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
          {formattedTurnover}
        </h4>
      </div>
            <div>
              {/* <h4 align="left">{allSavingPerformances.difTodayValue_ctr}</h4> */}
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
                {chartData && chartData?.series && (
                  <div className="chart-container area-chart">
                    <Chart
                      type="area"
                      align="center"
                      width={"100%"}
                      height={"400px"}
                      series={chartData.series}
                      options={{
                        ...chartData.options,
                        dataLabels: {
                          enabled: false, // Adiciona esta linha para ocultar os rótulos de dados
                        },
                        yaxis: {
                          labels: {
                            formatter: (value) => `${'$'}${value.toFixed(2)}`,
                          },
                        },
                        tooltip: {
                          y: {
                            formatter: (value) => `${'$'}${value.toFixed(2)}`,
                          },
                        },
                      }}
                    />
                  </div>
                )}
                <div className="chart-container donut-chart" style={{ marginTop: '5px' }}>
                  <Chart
                    align="center"
                    type="donut"
                    width="100%"
                    series={weightAssetMarks}
                    options={{
                      responsive: [
                        {
                          breakpoint: 281,
                          options: {
                            chart: {
                              width: '100%',
                              height: 150,
                            },
                          },
                        },
                        {
                          breakpoint: 361,
                          options: {
                            chart: {
                              width: '100%',
                              height: 230,
                            },
                          },
                        },
                        {
                          breakpoint: 481,
                          options: {
                            chart: {
                              width: '100%',
                              height: 300,
                            },
                          },
                        },
                        {
                          breakpoint: 769,
                          options: {
                            chart: {
                              width: '100%',
                              height: 275,
                            },
                          },
                        },
                        {
                          breakpoint: 768,
                          options: {
                            chart: {
                              width: '100%',
                              height: 300,
                            },
                          },
                        },
                        {
                          breakpoint: 1024,
                          options: {
                            chart: {
                              width: '100%',
                              height: 350,
                            },
                          },
                        },
                        {
                          breakpoint: 1200,
                          options: {
                            chart: {
                              width: '100%',
                              height: 350,
                            },
                          },
                        },
                        {
                          breakpoint: 2000,
                          options: {
                            chart: {
                              width: '100%',
                              height: 400,
                            },
                          },
                        },
                      ],
                      labels: weightAssetSubject,
                      noData: { text: 'No Data' },
                      plotOptions: {
                        pie: {
                          donut: {
                            labels: {
                              show: true,
                              name: {
                                show: true,
                                formatter: function (val) {
                                  return val;
                                },
                              },
                              value: {
                                show: true,
                                formatter: function (val) {
                                  return `$${parseFloat(val).toFixed(2)}`;
                                },
                              },
                              total: {
                                show: true,
                                label: 'Total',
                                formatter: function (w) {
                                  return `$${w.globals.seriesTotals.reduce((a, b) => a + b, 0).toFixed(2)}`;
                                },
                              },
                            },
                          },
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                          return `${parseFloat(val).toFixed(2)}%`;
                        },
                      },
                      legend: { show: false },
                    }}
                  />
                </div>
              </div>

              <style jsx>{`
                .chart-container {
                  flex: 1 1 100%;
                  max-width: 100%;
                }

                @media (min-width: 769px) {
                  .area-chart {
                    flex: 1 1 55%;
                    max-width: 55%;
                    min-width: 400px;
                  }

                  .donut-chart {
                    flex: 1 1 35%;
                    max-width: 35%;
                    min-width: 200px;
                  }
                }
              `}</style>
            </div>
            <hr />
            </Form>

            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              {/* <Button onClick={() => setFilter('all')} style={getButtonStyle('all')}>All</Button> */}
              <Button onClick={() => setFilter('open')} style={getButtonStyle('open')}>Open</Button>
              <Button onClick={() => setFilter('closed')} style={getButtonStyle('closed')}>Closed</Button>
            </div>
            <hr />

            <Row gutter={[8,24]}     
                style={{
                // height: 200,
                justifyContent: "center"
              }}>
                                  {
                  allUserSavingStats.map((opts,i)=>
                    <Card
                    key={i} value={opts._id}
                    size="small"
                    // hoverablepx
                    style={{ width: "320px", marginTop: 16, marginRight: "20px",marginLeft: "20px"}}
                    cover={[
                        <div align="center">
                        <br></br>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div style={{ marginLeft: "20px", alignSelf: "center" }}>
                            {
                            (opts.status === true) 
                              ? 
                              <Tooltip title="You Can Deposit or Close Portfolio">
                                <img alt="example" src={MyCheck} />
                              </Tooltip>
                              : 
                              <Tooltip title="Please wait, our robots are working on it... we will create a portfolio as soon a possible">
                                <img alt="example" src={MyMark}/>
                              </Tooltip>
                            },
                            </div>
                            <div style={{ marginRight: "20px", alignSelf: "center" }}>
                            <Tooltip title="Edit Portfolio">
                            <SettingOutlined
                            style={{marginLeft: "20px"}}
                            onClick={() => {
                              // console.log("opts",opts);
                              navigate('/EditSavings',
                              {
                                state:{
                                savingNameID: opts._id,
                                savingName: opts.savingName,
                                savingProcessID:  opts.savingProcessId,
                                categoryNameUpdate:  opts.categoryName.categoryName,
                                productNameUpdate:  opts.productName.productNamePT,
                                agentNameUpdate:  opts.agentName,
                                IBANUpdate:  opts.IBAN,
                                monthlyAmountUpdate:  opts.monthlyAmount,
                                dayPaymentUpdate:  opts.dayPayment,
                                isDepositActiveUpdate:  opts.isDepositActive,
                                takeProfitUpdate:  opts.takeProfit,
                                percentageTPUpdate:  opts.percentageTP,
                                stopLossUpdate:  opts.stopLoss,
                                percentageSLUpdate:  opts.percentageSL,
                                maxInvestCryptoUpdate: opts.maxInvestCrypto,
                                investmentTypeUpdate: opts.investmentType,
                                initialValueUpdate: opts.initialValue
                                }  
                              });
                            }}
                            />
                            </Tooltip>
                            </div>
                          </div>
                          <br />
                          <div align="center">
                            <div style={{justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ textAlign: "center", marginLeft: "20px", marginRight: "20px"}}>
                                    <h3 style={{ fontSize: "14px" }}><b>{opts.savingName}</b></h3>
                                </div>
                            </div>
                            <div>
                            {/* <div style={{ textAlign: "center", marginLeft: "50px", marginRight: "50px" }}>
                              <h3><b>{getSavingNameText(opts.savingName.categoryName)}</b></h3>
                            </div> */}
                            <br></br>
                            <div style={{ textAlign: "center", marginLeft: "50px", marginRight: "50px" }}>
                              <img style={{ width: '64px', height: '64px' }} alt="example" src={getSavingNameImage(opts.categoryName)} />
                            </div>
                          </div>
                            <br></br>
                            <div style={{justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ textAlign: "center", marginLeft: "5px", marginRight: "5px"}}>
                                    <h5 ><b style={{ fontSize: "14px" }}>{opts.productName.productName}</b></h5>
                                </div>
                            </div>
                            <hr></hr>
                            {/* <div style={{justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ textAlign: "center", marginLeft: "5px", marginRight: "5px", marginTop: "5px"}}>
                                    <h5><b>{(opts.today_value).toLocaleString('de-DE', { style: 'currency', currency: opts.currency })}</b>
                                    <br></br>
                                    <br></br>
                                    <span style={{ color: "#adb5bd" }}>Balance</span></h5>
                                </div>
                            </div>
                            <br></br> */}
                            <div style={{ display: "flex", justifyContent: "space-between" }} className="small-font-mobile">
                              <div style={{ textAlign: "left"}}>
                                <h5 style={{ textAlign: "center", marginLeft:"20px"}}>{(opts.maxInvestCrypto).toLocaleString('de-DE', { style: 'currency', currency: "USD" })}<br/>
                                <br></br>
                                <span style={{ color: "#adb5bd" }}>P/L</span></h5>
                              </div>
                              <div style={{ textAlign: "center"}}>
                                    <h5>{(opts.initialValue).toLocaleString('de-DE', { style: 'currency', currency: "USD" })}
                                    <br></br>
                                    <br></br>
                                    <span style={{ color: "#adb5bd" }}>Balance</span></h5>
                                </div>
                              <div style={{ textAlign: "right"}}>
                                <h5 style={{ textAlign: "center", marginRight:"20px" }}>{parseFloat("0").toFixed(3)} %<br/>
                                <br></br>
                                <span style={{ color: "#adb5bd" }}> Return</span></h5>
                              </div>
                            </div>
                            {/* <br></br>
                            <hr></hr>
                            <p>Updated portfolio<br></br><span className="quantity">{moment(opts.dateSavingPerformance).format("YYYY-MM-DD HH:mm")}</span></p> */}
                        </div>
                      </div>
                    ]}
                    actions={[
                      <Tooltip title="Deposit">
                        <UploadOutlined key="setting"/>
                      </Tooltip>,
                      <Tooltip title="Close Portfolio">
                        <DownloadOutlined key="setting"/>
                      </Tooltip>,
                      <Tooltip title="Details Portfolio">
                      <PieChartOutlined/>
                      </Tooltip>,
                    ]}
                    >
                    </Card>)
                  }
                  {
                  filteredSavingStats.map((opts,i)=>
                    !opts.savingName.archivePortfolio ? (
                    <Card
                    key={i} value={opts._id}
                    size="small"
                    // hoverablepx
                    style={{ width: "300px", marginTop: 16, marginRight: "20px",marginLeft: "20px",backgroundColor:"#172B4D", color:"white"}}
                    cover={[
                        <div align="center">
                        <br></br>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ marginLeft: "20px", alignSelf: "center" }}>
                              {opts.savingName.closePortfolio === false ? (
                                opts.reforceInvestment === false ? (
                                  <Tooltip title="You Can Deposit or Close Portfolio">
                                    <img alt="example" src={MyCheck} />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Please wait, our robots are working on it... you can use it from 00:00 GMT">
                                    <img alt="example" src={MyMark} />
                                  </Tooltip>
                                )
                              ) : (
                                <Tooltip title="Portfolio closed">
                                  <img alt="example" src={MyCross} />
                                </Tooltip>
                              )}
                            </div>
                            <div style={{ marginRight: "20px", alignSelf: "center" }}>
                            <Tooltip title="Edit Portfolio">
                            <SettingOutlined
                            style={{marginLeft: "20px"}}
                            onClick={() => {
                              // console.log("opts",opts);
                              navigate('/EditSavings',
                              {
                                state:{
                                savingNameID: opts.savingName._id,
                                savingName: opts.savingName.savingName,
                                savingProcessID:  opts.savingProcessId,
                                categoryNameUpdate:  opts.savingName.categoryName,
                                productNameUpdate:  opts.productName.productNamePT,
                                agentNameUpdate:  opts.agentName,
                                IBANUpdate:  opts.savingName.IBAN,
                                monthlyAmountUpdate:  opts.savingName.monthlyAmount,
                                dayPaymentUpdate:  opts.savingName.dayPayment,
                                isDepositActiveUpdate:  opts.savingName.isDepositActive,
                                takeProfitUpdate:  opts.savingName.takeProfit,
                                percentageTPUpdate:  opts.savingName.percentageTP,
                                stopLossUpdate:  opts.savingName.stopLoss,
                                percentageSLUpdate:  opts.savingName.percentageSL,
                                maxInvestCryptoUpdate: opts.savingName.maxInvestCrypto,
                                investmentTypeUpdate: opts.savingName.investmentType,
                                initialValueUpdate: opts.savingName.initialValue,
                                closePortfolioUpdate: opts.savingName.closePortfolio,
                                archivePortfolioUpdate:opts.savingName.archivePortfolio,
                                  }
                                });
                            }}
                            />
                            </Tooltip>
                            </div>
                          </div>
                          <br />
                          <div align="center">
                            <div style={{justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ textAlign: "center", marginLeft: "20px", marginRight: "20px"}}>
                                    <h3 style={{ fontSize: "20px" }}><b>{opts.savingName.savingName}</b></h3>
                                </div>
                            </div>
                            <div>
                            {/* <div style={{ textAlign: "center", marginLeft: "50px", marginRight: "50px" }}>
                              <h3><b>{getSavingNameText(opts.savingName.categoryName)}</b></h3>
                            </div> */}
                            <br></br>
                            <div style={{ textAlign: "center", marginLeft: "50px", marginRight: "50px" }}>
                              <img style={{ width: '64px', height: '64px' }} alt="example" src={getSavingNameImage(opts.savingName.categoryName)} />
                            </div>
                          </div>
                            <br></br>
                            <div style={{justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ textAlign: "center", marginLeft: "5px", marginRight: "5px"}}>
                                    <h5><b>{opts.productName.productName}</b></h5>
                                </div>
                            </div>
                            <hr></hr>
                            {/* <div style={{justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ textAlign: "center", marginLeft: "5px", marginRight: "5px", marginTop: "5px"}}>
                                    <h5><b>{(opts.today_value).toLocaleString('de-DE', { style: 'currency', currency: opts.currency })}</b>
                                    <br></br>
                                    <br></br>
                                    <span style={{ color: "#adb5bd" }}>Balance</span></h5>
                                </div>
                            </div>
                            <br></br> */}
                            <div style={{ display: "flex", justifyContent: "space-between"}} className="small-font-mobile">
                              <div style={{ textAlign: "left"}}>
                                <h5 style={{ textAlign: "center", marginLeft:"20px", fontSize:"16px" }}>{(opts.diffAccum).toLocaleString('de-DE', { style: 'currency', currency: opts.currency })}<br/>
                                <br></br>
                                <span style={{ color: "#adb5bd" }}>P/L</span></h5>
                              </div>
                              <div style={{ textAlign: "center"}}>
                                    <h5 style={{ fontSize:"16px", fontSize:"16px" }}>{(opts.today_value).toLocaleString('de-DE', { style: 'currency', currency: opts.currency })}
                                    <br></br>
                                    <br></br>
                                    <span style={{ color: "#adb5bd", fontSize:"16px" }}>Balance</span></h5>
                                </div>
                              <div style={{ textAlign: "right"}}>
                                <h5 style={{ textAlign: "center", marginRight:"20px", fontSize:"16px" }}>{parseFloat(opts.pctTodayValueAccum*100).toFixed(3)} %<br/>
                                <br></br>
                                <span style={{ color: "#adb5bd", fontSize:"16px" }}> Return</span></h5>
                              </div>
                            </div>
                            {/* <br></br>
                            <hr></hr>
                            <p>Updated portfolio<br></br><span className="quantity">{moment(opts.dateSavingPerformance).format("YYYY-MM-DD HH:mm")}</span></p> */}
                        </div>
                      </div>
                    ]}
                    actions={[
                        !opts.reforceInvestment ? (
                        <Tooltip title="Deposit">
                          <UploadOutlined
                            key="setting"
                            onClick={() => {
                              if (!opts.savingName.closePortfolio) {
                              // console.log(opts);
                              navigate('/SavingDeposit', {
                                state: {
                                  savingNameID: opts.savingName._id,
                                  savingName: opts.savingName.savingName,
                                  savingProcessID: opts.savingProcessId,
                                  productName: opts.productName.productName,
                                  productNameID: opts.productName._id,
                                  minimumInvestment: opts.productName.minimumInvestment,
                                  investmentType: opts.savingName.investmentType,
                                  exchangeName: opts.exchangeName.id,
                                }
                              })
                            };
                            }}
                          />
                        </Tooltip>
                        ) : (
                          null
                        ),

                      !opts.reforceInvestment ? (
                      <Tooltip title="Close Portfolio">
                      <DownloadOutlined key="setting"
                      onClick={() => {
                        if (!opts.savingName.closePortfolio) {
                        // console.log(opts);
                        navigate('/CloseSavings', {
                          state: {
                            savingNameID: opts.savingName._id,
                            savingName: opts.savingName.savingName,
                            savingProcessID: opts.savingProcessId,
                            productName: opts.productName.productName,
                            productNameID: opts.productName._id,
                            minimumInvestment: opts.productName.minimumInvestment,
                            investmentType: opts.savingName.investmentType,
                            exchangeName: opts.exchangeName.id,
                          }
                        })
                        };
                      }}
                      />
                      </Tooltip>
                      ) : (
                        null
                      ),

                      // <Tooltip title="Order Transactions">
                      // <UnorderedListOutlined key="setting"
                      // onClick={() => {
                      //   console.log(opts);
                      //   navigate('/OrderTransactions',
                      //   {
                      //     state:{
                      //       savingNameID: opts.savingName._id,
                      //       savingName: opts.savingName.savingName,
                      //       savingProcessID: opts.savingName.savingProcessId,
                      //       productName: opts.productName.productName,     
                      //     }
                      //   });
                      // }}
                      // />
                      // </Tooltip>,

                      // <Tooltip title="Performance Portfolio">
                      // <StockOutlined key="setting"
                      // onClick={() => {
                      //   console.log(opts);
                      //   navigate('/SavingPortfolioPerformance',
                      //   {
                      //     state:{
                      //       savingNameID: opts.savingName._id,
                      //       savingName: opts.savingName.savingName,
                      //       savingProcessID: opts.savingProcessId,
                      //       productName: opts.productName.productName,     
                      //     }
                      //   });
                      // }}
                      // />
                      // </Tooltip>,

                      <Tooltip title="Details Portfolio">
                      <PieChartOutlined
                      onClick={() => {
                        // console.log("opts:",opts);
                        navigate('/SavingPortfolio',
                        {
                          state:{
                            savingNameID: opts.savingName._id,
                            savingName: opts.savingName.savingName,
                            savingProcessID: opts.savingProcessId,
                            productName:  opts.productName.productName,
                            closePortfolio: opts.savingName.closePortfolio    
                          }
                        });
                      }}
                      />
                      </Tooltip>,

                    ]}
                    >
                    </Card>
                    ) : (
                      null
                    ),
                  )}

                  {/* Conditionally rendering the Card component */}
                  {filter !== 'closed' && (
                    <Card
                      onClick={() => navigate('/NewSavings')}
                      size="small"
                      hoverable
                      style={{ width: '300px', marginTop: 16, marginRight: '20px', marginLeft: '20px' }}
                      cover={(
                        <div align="center">
                          <br />
                          <div>
                            <div align="right" style={{ marginRight: '20px' }}>
                              {(() => {
                                return <div></div>;
                              })()}
                            </div>
                          </div>
                          <div>
                            <br />
                            <img alt="example" src={MySaving} />
                          </div>
                          <div>
                            <br />
                            <h4 className="price">New</h4>
                          </div>
                        </div>
                      )}
                    >
                      <Meta
                        description={(
                          <div align="center">
                            <h4 className="price" style={{ marginRight: '20px', marginLeft: '20px' }}>
                              CRYPTO BASKET
                            </h4>
                          </div>
                        )}
                      />
                      <div align="center">
                        <br />
                        <img alt="example" src={MyPlus} />
                      </div>
                    </Card>
                  )}

              {/* <Card
              onClick={() => {
                navigate('/NewSavings');
              }}
              size="small"
              hoverable
              style={{ width: "300px", marginTop: 16, marginRight: "20px",marginLeft: "20px"}}
              cover={(
                <div align="center">
                  <br />
                  <div>
                    <div align="right" style={{ marginRight: "20px" }}>
                      {(() => {
                        return (
                          <div></div>
                        );
                      })()}
                    </div>
                  </div>
                  <div>
                    <br />
                    <img alt="example" src={MySaving} />
                  </div>
                  <div>
                    <br />
                    <h4 className="price">New</h4>
                  </div>
                </div>
              )}
            >
              <Meta
                description={(
                  <div align="center">
                    <h4 className="price" style={{ marginRight: "20px", marginLeft: "20px" }}>CRYPTO BASKET</h4>
                  </div>
                )}
              />
              <div align="center">
              <br />
                <img alt="example" src={MyPlus} />
              </div>
            </Card> */}
        </Row>
    </Layout>
  );
};

export default MySavings;