import React,{useState,useEffect} from "react";
import {Form, Input, message, Modal, Table, Switch, Select, Button,Space,Col,Row} from 'antd';

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import moment from 'moment';

import {useNavigate } from "react-router-dom";

import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  PieChartOutlined
} from "@ant-design/icons";

const MyProducts = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const { TextArea } = Input;

  const [showModal, setShowModal] = useState(false);

  const [loading,setLoading] = useState(false);

  //All Products
  const [allProduct, setAllProduct] = useState([]);

  //DropList Category Asset
  const [allAsset, setAllAsset] = useState([]);

  //DropList Category Asset
  const [allCategoryAsset, setAllCategoryAsset] = useState([]);

  //Handle Close button Form
  const [show, setShow] = useState(false);

  //Edit Transactions
  const [editable, setEditable] = useState(null);

  //Fields from table to View
  const [productNameUpdate , setProductnameUpdate]  = useState(null);
  const [brokerNameUpdate , setBrokernameUpdate]  = useState(null);
  const [shortDescriptionUpdate , setShortDescriptionUpdate]  = useState(null);
  const [marketSelectionUpdate , setMarketSelectionUpdate]  = useState(null);
  const [allocationsUpdate , setAllocationsUpdate]  = useState(null);
  const [expectedAnnualIncomeUpdate , setExpectedAnnualIncomeUpdate]  = useState(null);
  const [assetsUpdate , setAssetsUpdate]  = useState(null);
  const [isrUpdate , setISRUpdate]  = useState(null);
  const [currencyUpdate , setCurrencyUpdate]  = useState(null);
  const [categoryAssetNameUpdate , setCategoryAssetNameUpdate]  = useState(null);
  const [tooltipExpectAnnualIncomeUpdate , setTooltipExpectAnnualIncomeUpdate]  = useState(null);
  const [rebalancingUpdate , setRebalancingUpdate]  = useState(null);
  const [logoURLUpdate , setLogoURLUpdate]  = useState(null);
  const [ifiURLUpdate, setIfiURLUpdate] = useState(null);
  const [startDateProductUpdate , setStartDateProductUpdate]  = useState(null);
  const [orderUpdate , setOrderUpdate]  = useState(null);
  const [investmentTypeUpdate , setInvestmentTypeUpdate]  = useState(null);
  const [minimumInvestmentUpdate , setMinimumInvestmentUpdate]  = useState(null);
  const [statusUpdate , setStatusUpdate]  = useState(null);
  
  //table users **************************************************************************************************************************
  const columns =[
    {
      key: "order",
      title: "Ordenação",
      dataIndex: "order",
      align: "center",
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.order-b.order,
    },
    {
      key: "productName",
      title: "Nome do Produto",
      dataIndex: "productName",
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.assetName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "brokerName",
      title: "Nome do Broker",
      dataIndex: "brokerName",
      sorter: (a, b) => a.brokerName.localeCompare(b.brokerName),
    },
    {
      key: "categoryAssetName",
      title: "Classe Ativo",
      dataIndex: ["categoryAssetName","categoryAssetName"],
      sorter: (a, b) => a.categoryAssetName.categoryAssetName.localeCompare(b.categoryAssetName.categoryAssetName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.categoryAssetName.categoryAssetName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key:"isr",
      title: "ISR (3 anos)",
      dataIndex: "isr",
      align:"center",
      sorter: (a, b) => a.isr - b.isr,
      render: (value, row, index) => {
        // do something like adding commas to the value or prefix
        return <span>{value.toLocaleString('pt-PT')}</span>;
      },
    },
    {
      key:"expectedAnnualIncome",
      title: "Retorno Anual Esperado (3 anos)",
      dataIndex: "expectedAnnualIncome",
      align:"center",
      sorter: (a, b) => a.expectedAnnualIncome - b.expectedAnnualIncome,
      render: (value, row, index) => {
        // do something like adding commas to the value or prefix
        return <span>{value.toLocaleString('pt-PT')} %</span>;
      },
    },
    {
      key: "minimumInvestment",
      title: "Minimo Investimento",
      dataIndex: ["minimumInvestment"],
      align:"center",
      sorter: (a, b) => a.minimumInvestment-b.minimumInvestment,
    },
    {
      key: "currency",
      title: "Moeda",
      dataIndex: ["currency"],
      align:"center",
      sorter: (a, b) => a.currency.localeCompare(b.currency),
    },
    {
      key: "startDateProduct",
      title: "Inicio do Produto",
      dataIndex: "startDateProduct",
      align:"center",
      render : (text) => <span>{moment(text).format("YYYY-MM")}</span>,
      sorter: (a, b) => new Date(a.startDateProduct) - new Date(b.startDateProduct)
    },
    {
      key: "status",
      title: "Estado",
      dataIndex: "status",
      align:"center",
      render: (value, record) => {
        if(record.status === true )
          return (
            <span style={{color: "#39A939"}}>Ativo</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Desactivo</span>
          );
      },
      filters: [
        { text: "Ativo", value: true },
        { text: "Desativo", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        // console.log(typeof value)
        return record.status === value;
      },
    },
    {
      key: "investmentType",
      title: "Tipo Investimento",
      dataIndex: "investmentType",
      align:"center",
      render: (value, record) => {
        if(record.investmentType === true )
          return (
            <span style={{color: "#39A939"}}>Ativo</span>
          );

          return (
            <span style={{color: "#FF3234"}}>Passivo</span>
          );
      },
      filters: [
        { text: "Ativo", value: true },
        { text: "Passivo", value: false },
      ],
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        // console.log(typeof value)
        return record.investmentType === value;
      },
    },
    {
      title: "Ações",
      dataIndex: "type",
      render: (type, record) => {
        // if(type === "Income" )
        return (
          <div className="mx-2">
            <Space>
            <PieChartOutlined
               onClick={() => {
                 navigate('/PortfolioProducts',
                 {
                   state:{
                     portfolioProductId: record.id,
                     productName:record.productName,
                     productNamePT:record.productNamePT        
                   }
                 });
               }}
            />
            <EditOutlined
              onClick={() => {
                    setEditable(record);
                    setProductnameUpdate(record.productName);
                    setBrokernameUpdate(record.brokerName);
                    setShortDescriptionUpdate(record.shortDescription);
                    setMarketSelectionUpdate(record.marketSelection);
                    setAllocationsUpdate(record.allocations);
                    setExpectedAnnualIncomeUpdate(record.expectedAnnualIncome);
                    setAssetsUpdate(record.assets);
                    setISRUpdate(record.isr);
                    setCurrencyUpdate(record.currency);
                    setCategoryAssetNameUpdate(record.categoryAssetName.categoryAssetName);
                    setTooltipExpectAnnualIncomeUpdate(record.tooltipExpectAnnualIncome);
                    setRebalancingUpdate(record.rebalancing);
                    setLogoURLUpdate(record.logoURL);
                    setIfiURLUpdate(record.ifiURL);
                    setStartDateProductUpdate(record.startDateProduct);
                    setOrderUpdate(record.order);
                    setStatusUpdate(record.status);
                    setInvestmentTypeUpdate(record.investmentType);
                    setMinimumInvestmentUpdate(record.minimumInvestment);             
                    setShowModal(true);
              }}
            />
            <DeleteOutlined
                onClick={() => {
                handleDelete(record);
            }}
            />
            </Space>
          </div>
        );
      },
    }
  ];

  const user = JSON.parse(localStorage.getItem('user'))

    //useEffects Hook **************************************************************************************************************************
  //List of Assets
  useEffect(() => {
    const getAllAsset = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/myproducts/get-product',
        {
          status:"all"
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllProduct(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
      }
    };  
    
    getAllAsset();
  },[])

  //Handlers Submit  **************************************************************************************************************************
  const handleSubmitAsset = async (values) => {

  try{
    const promises = [];
  
    const user = JSON.parse(localStorage.getItem('user'))

    if(editable){
      setLoading(true);
      const result = axios.post('/myproducts/edit-product', {
        payload:{
          ...values,
          userid: user._id,
        },
        _id: editable._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Produto atualizado com sucesso!');
      setTimeout(function () {
        window.location.reload(true);
        message.success('reload 1 segundo');
        }, 1000);
    }else{
      setLoading(true);
      const result = axios.post('/myproducts/add-product', {
        ...values, 
        userid:user._id, 
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Produto criado com sucesso!');
      navigate('/ProcessingProduct',
      {
        state:{
          productName:values.productName,
          processing:"Creating New Product",
        }
      }
    );
    }
    setEditable(null);
    }catch (error){
      setLoading(false);
    }
  }

  //Handler Asset Class DropList ****************************************************************************************
  useEffect(() => {
    const getAllCategoryAsset = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/categoryAssets/get-categoryAsset', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllCategoryAsset(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllCategoryAsset();
  },[])

  //Handler Asset Class DropList ****************************************************************************************
  useEffect(() => {
    const getAllAsset = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/cryptoAssets/get-cryptoAsset', {
          userid:user._id,
          status: true
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setAllAsset(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllAsset();
  },[])

  // //Handler Test Console Log Values
  // const onFinish = (values) => {
  //   console.log({values});
  // };

  //delete handler
  const handleDelete = async (record) => {
    try {
        setLoading(true);
        await axios.post("/myproducts/delete-product", {
        _id: record._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        message.success("Ativo apagada com sucesso!");
        setTimeout(function () {
        window.location.reload(true);
        message.success('reload 1 segundo');
        }, 1000);
    } catch (error) {
        setLoading(false);
        // console.log(error);
        message.error("unable to delete");
    }
    };

  //Handler Reset Values to modal
  const handleCancelModal = () => {
    setShowModal(false);
    setEditable(null);
    setProductnameUpdate(null);
    setBrokernameUpdate(null);
    setShortDescriptionUpdate(null);
    setMarketSelectionUpdate(null);
    setAllocationsUpdate(null);
    setExpectedAnnualIncomeUpdate(null);
    setAssetsUpdate(null);
    setISRUpdate(null);
    setCurrencyUpdate(null);
    setCategoryAssetNameUpdate(null);
    setTooltipExpectAnnualIncomeUpdate(null);
    setRebalancingUpdate(null);
    setLogoURLUpdate(null);
    setIfiURLUpdate(null);
    setStartDateProductUpdate(null);
    setOrderUpdate(null);
    setMinimumInvestmentUpdate(null);
    setStatusUpdate(null);  
  };

  /************************************LOGO DISABLE BUTTON***************************/

  let logoURLUDisable;

  if (logoURLUpdate) {
    logoURLUDisable = 
    <div>
      <img src={logoURLUpdate} alt={productNameUpdate} style={{ width: '150px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}}/>
    </div>
  } 
  
  /************************************DATE DISABLE***************************/
  let dateDisable;
  if (!startDateProductUpdate) {
      dateDisable = 
      <Form.Item label="Data inicio do produto" name="startDateProduct"
          rules={[
              { required: true, whitespace:true, message: 'Inserir data produto do ativo!' },
              ]}>
          <Input type="date" defaultValue={startDateProductUpdate}/>
      </Form.Item>
      ;
  } else {
      dateDisable =
      <Form.Item label="Data inicio do produto" name="startDateProduct">
      <Input 
          type="text"
          defaultValue={moment(startDateProductUpdate).format("YYYY-MM")}
          disabled="true"
      />
  </Form.Item>;
  }

    /************************************ASSETS DISABLE***************************/
    let assetDisable;
    if (!assetsUpdate) {
      assetDisable = 
      <Form.List name="assets" >
          {(fields, { add, remove }) => (
            <div>
              {fields.map((field, index) => (
                <div key={field.key}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        name={[field.name, 'Asset']}
                        label="Ativo alocado no produto" 
                        fieldKey={[field.fieldKey, 'Asset']}
                        className="formItemKeyDependency"
                        rules={[{ required: true, message: 'Preencha com o Activo Investido' }]}
                      >
                        <Select
                          placeholder="Activo"
                          style={{ width: '100%' }}
                        >
                          {allAsset.map(item => (
                            <Select.Option key={item} value={item._id}>
                              {item.cryptoAssetName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name={[field.name, 'percentage']}
                        fieldKey={[field.fieldKey, 'percentage']}
                        className="formItemKeyDependency"
                        rules={[{ required: true, message: 'Preencha com o Alocação Investida' }]}
                      >
                        <Input style={{ width: '220px' }}
                            placeholder='0.00'
                            suffix="%" 
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Col xs={24} sm={24} md={1} lg={1} xl={1} key={3}>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Col>
                </div>
              ))}
            <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                add();
              }}
              style={{ width: "100%" , 
              // background: "green", color: "white", 
              marginTop: 30, marginBottom: 30}}
            >
              <PlusOutlined /> Novo Ativo
            </Button>
          </Form.Item>
        </div>
      )}
    </Form.List>;
    } else {
    }

  /************************************IFI DISABLE BUTTON***************************/
  let ifiInputDisable;

  if (!setIfiURLUpdate) {
    ifiInputDisable = 
    <Form.Item label="Informações Fundamentais destinadas aos Investidores" name="ifiURL">
        <Input 
        type="text"
        />
    </Form.Item>
  } else {
    ifiInputDisable = 
    <Form.Item label="Informações Fundamentais destinadas aos Investidores" name="ifiURL">
      <Space.Compact style={{ width: '100%' }}>
        <Input 
        type="text"
        defaultValue={ifiURLUpdate} />
        <Button type="primary" onClick={() => window.open(ifiURLUpdate, "Popup","width=600, height=600")}>Ver PDF</Button>
      </Space.Compact>
    </Form.Item>
  }

  //Handler Close Modal
  const handleClose = () => {
    setShow(false);
  };

  //APP  **************************************************************************************************************************
  return (
    <Layout>
      {loading && <Spinner/>}
      <div className="accountpage">
        <div></div>
        <div><h1>Definições - Produtos</h1></div>
        <div></div>
      </div>

      <div className="accountpagebuttonsAll">
          <Button
          align="right"
          shape="round"
          className="btn btn-primary"
          onClick={() => setShowModal(true)}
          >+ Novo Produto
        </Button>
        </div>

      <div className="accountpagebuttonsAll">
      <Button
        shape="round"
        className="btn btn-primary btn-block"
        style={{ background: "green", borderColor: "green" }}
        onClick={() => navigate('/ProcessingRefreshCryptoBasketDaily')}
      >
        Refresh Crypto-Basket
      </Button>
      </div>

      <div className="accountpagebuttonsAll">
          <Button
            shape="round"
            className="btn btn-primary btn-block"
            style={{ background: "blue", borderColor: "blue" }}
            onClick={() => navigate('/ExplorerSettings')}
          >
            Voltar
          </Button>
        </div>

      <hr></hr>
      <div className="content">
        <Table
        size="small" 
        columns={columns}
        dataSource={allProduct}
        pagination={{ defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100']}}
        scroll={{ x: 'max-content'}}
        className="custom-table"
        />
        
      </div>
      {/* <div><pre>{JSON.stringify(allEtfAsset, null, 2) }</pre></div>
      <div><pre>{JSON.stringify(allMarket, null, 2) }</pre></div> */}
      
      <Modal
        destroyOnClose={true} 
        title={editable ? "Editar Produto" : "Criar Produto" }
        open={showModal}
        onCancel={handleCancelModal}
        footer={false}
        style={{padding:"32px 16px"}}
        >
          <Form 
            layout="vertical" 
            onFinish={handleSubmitAsset} 
            style={{padding:"32px 16px"}}
          >

          {/* See the button view */}
          {logoURLUDisable}

          <Form.Item label="Ordenação" name="order" style={{marginTop:"25px"}}>
              <Input 
                type="number"
                defaultValue={orderUpdate}
                />
          </Form.Item>

          {dateDisable}

            <Form.Item label="Nome do Produto" name="productName">
              <Input 
                type="text"
                defaultValue={productNameUpdate}
                />
            </Form.Item>

            <Form.Item label="Minimo de Investimento" name="minimumInvestment" style={{marginTop:"25px"}}>
              <Input 
                type="number"
                defaultValue={minimumInvestmentUpdate}
                />
            </Form.Item>

            <Form.Item label="Tipo de Investimento" name="investmentType" valuePropName="checked">
              <Switch 
                checkedChildren="ACTIVE" unCheckedChildren="PASSIVE" defaultChecked={investmentTypeUpdate}
              />
            </Form.Item>

            <Form.Item label="Nome do Broker" name="brokerName">
              <Select defaultValue={brokerNameUpdate}>
              <Select.Option value="BINANCE">BINANCE</Select.Option>
              </Select>
            </Form.Item>

            {/* See the button view */}
            {ifiInputDisable}

            <Form.Item label="Pequena descrição" name="shortDescription">
              <TextArea
                showCount
                maxLength={25}
                style={{
                  height: 150,
                  marginBottom: 12,
                }}
                type="text" 
                defaultValue={shortDescriptionUpdate}
                />
            </Form.Item>

            <Form.Item label="Selecção de Mercado" name="marketSelection">
            <TextArea
                  showCount
                  maxLength={250}
                  style={{
                    height: 125,
                    marginBottom: 12,
                  }}
                defaultValue={marketSelectionUpdate}
                />
            </Form.Item>

            <Form.Item label="Alocações" name="allocations">
                <TextArea
                  showCount
                  maxLength={250}
                  style={{
                    height: 125,
                    marginBottom: 12,
                  }}
                defaultValue={allocationsUpdate}
                />
            </Form.Item>
            <Form.Item label="Rebalanceamento" name="rebalancing">
                <TextArea
                  showCount
                  maxLength={250}
                  style={{
                    height: 125,
                    marginBottom: 12,
                  }}
                  type="text" 
                  defaultValue={rebalancingUpdate}
                />
            </Form.Item>

            <Form.Item label="Retorno Anual Esperado (3 Anos)" name="expectedAnnualIncome">
              <Input
                placeholder='0.00'
                suffix="%" 
                type="number"
                defaultValue={expectedAnnualIncomeUpdate}
                />
          </Form.Item>

          <Form.Item label="Tooltip Retorno Anual Esperado" name="tooltipExpectAnnualIncome">
                <TextArea
                  showCount
                  maxLength={250}
                  style={{
                    height: 125,
                    marginBottom: 12,
                  }}
                  type="text" 
                  defaultValue={tooltipExpectAnnualIncomeUpdate}
                />
            </Form.Item>
          
            {/* See the button view */}
            {assetDisable}

            <Form.Item label="LogoURL" name="logoURL">
              <Input 
                type="text"
                defaultValue={logoURLUpdate}
                />
            </Form.Item>

            <Form.Item label="ISR (3 Anos)" name="isr">
              <Input 
                type="number"
                placeholder='0'
                defaultValue={isrUpdate}
                />
            </Form.Item>

            <Form.Item label="Classe Ativo" name="categoryAssetName">
              <Select
                defaultValue={categoryAssetNameUpdate}
              >
                  {
                  allCategoryAsset.map((opts,i)=>
                    <Select.Option key={i} value={opts._id}>
                      {opts.categoryAssetName}
                    </Select.Option>)
                  }
              </Select>
            </Form.Item>

            <Form.Item label="Moeda" name="currency">
              <Select defaultValue={currencyUpdate}>
              <Select.Option value="USDT">USDT</Select.Option>
                {/* <Select.Option value="EUR">EUR</Select.Option> 
                <Select.Option value="USD">USD</Select.Option>
                <Select.Option value="CHF">CHF</Select.Option>
                <Select.Option value="GBP">GBP</Select.Option>
                <Select.Option value="BRL">BRL</Select.Option> */}
              </Select>
            </Form.Item>

            <Form.Item label="Estado" name="status" valuePropName="checked">
              <Switch 
                checkedChildren="ACTIVE" unCheckedChildren="INACTIVE" defaultChecked={statusUpdate}
              />
            </Form.Item>
            <button type="submit" style={{ width: "100%" , background: "green", color: "white", marginTop: 30, marginBottom: 30}} className="btn btn-success" onClick={handleClose}>
                  {" "}
                  GRAVAR
            </button>

            {/* <div><pre>{JSON.stringify(allMarket, null, 2) }</pre></div> */}
            
          </Form>
      </Modal>

    </Layout>
  );
};

export default MyProducts;