import React,{useState,useEffect} from "react";
import {Input, Tabs,Form,Button, Radio,message ,Tooltip,Row,Card,Table} from 'antd';
import MyWallet from "./../components/Assets/binance.png";
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";
import { ArrowDownOutlined, ArrowUpOutlined, CheckCircleFilled,ExclamationCircleFilled, HddOutlined, MinusOutlined, PieChartOutlined, PlusOutlined, ReloadOutlined, SettingOutlined, StockOutlined, TableOutlined, UnorderedListOutlined, UploadOutlined } from "@ant-design/icons";

import moment from 'moment';
import  Chart  from "react-apexcharts";

const MyInvestments = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    window.scrollTo(0, 0);
  }, []);

  const user = JSON.parse(localStorage.getItem('user'))
  const user_currency = user.currency

  const currencySymbol = user_currency === 'USD' ? '$' : '€';


  const investor = JSON.parse(localStorage.getItem('investorProfile'))
  // console.log(investor)

  const { Meta } = Card;
  const { TabPane } = Tabs;

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allExchangePortfolio, setAllExchangePortfolio] = useState([]);

  const [activeTab, setActiveTab] = useState('Aggregate');

  const handleTabChange = (key) => {
    setActiveTab(key);
  };


  const [brokerDetails, setBrokerDetails] = useState(null);

  const onChangeBroker = ({ target: { value } }) => {
    // console.log('Broker choose: ', value);
    setBrokerDetails(value);
  };

  const [allInvestmentStats, setAllInvestmentStats] = useState([]);
  const [allStats, setAllStats] = useState([]);
  const [allBalanceStats, setAllBalanceStats] = useState([]);
  const [cashExchanges, setCashExchanges] = useState([]);

  //Fields from Graphs to View
  const [allInvestmentTypeWeights, setAllInvestmentTypeWeights] = useState(null);
  const [allAllInvestmentIsProtectedWeights, setAllInvestmentIsProtectedWeights] = useState({});

  const [distinctExchanges, setDistinctExchanges] = useState([]);

  const currentDate = new Date();
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
  };

  const formattedDate = currentDate.toLocaleDateString('sv-SE', options);

    //table accounts
    const columns =[
      {
        key: "cryptoAssetName",
        title: "Crypto Name",
        dataIndex: ["cryptoAssetName"],
        sorter: (a, b) => a.cryptoAssetName.localeCompare(b.cryptoAssetName),
      },
      {
        key: "symbol",
        title: "Symbol",
        dataIndex: ["symbol"],
        sorter: (a, b) => a.symbol.localeCompare(b.symbol),
      },
      {
        key: "qty",
        title: "Quantity",
        dataIndex: "qty",
        align: "center",
        sorter: (a, b) => a.qty-b.qty,
        render: (qty, record) => {
          return (
            <span>
                {qty.toLocaleString('de-DE', {
                    maximumFractionDigits: 5
                })}
            </span>
        );
        },
      },
      {
        key: "locked",
        title: "Quantity Locked",
        dataIndex: "locked",
        align: "center",
        sorter: (a, b) => a.locked-b.locked,
        render: (locked, record) => {
          return (
            <span>
                {locked.toLocaleString('de-DE', {
                    maximumFractionDigits: 5
                })}
            </span>
          );
        },
      },
      {
        key: "amountPerQty",
        title: "Average Price USDT",
        dataIndex: "amountPerQty",
        align: "right",
        sorter: (a, b) => (a.amountOriginal / a.qty) - (b.amountOriginal / b.qty),
        render: (text, record) => {
            const amountPerQty = record.amountOriginal / record.qty;
            return (
                <span>
                    {amountPerQty.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: record.currency,
                        maximumFractionDigits: record.ticketSize
                    })}
                </span>
            );
        },
      },
      {
        key: "amountPerQty",
        title: "Average Price",
        dataIndex: "amountPerQty",
        align: "right",
        sorter: (a, b) => (a.amount / a.qty) - (b.amount / b.qty),
        render: (text, record) => {
            const amountPerQty = record.amount / record.qty;
            return (
                <span>
                    {amountPerQty.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: record.currency_ctr,
                        maximumFractionDigits: record.ticketSize
                    })}
                </span>
            );
        },
      },
      {
        key: "amountOriginal",
        title: "Value USDT",
        dataIndex: "amountOriginal",
        align: "right",
        sorter: (a, b) => a.amountOriginal-b.amountOriginal,
        render: (amountOriginal, record) => {
            return (
              <span>{(amountOriginal).toLocaleString('de-DE', { style: 'currency', currency: record.currency,maximumFractionDigits: 2})}</span>
              );
        },
      },
      {
        key: "amount",
        title: "Value",
        dataIndex: "amount",
        align: "right",
        sorter: (a, b) => a.amount-b.amount,
        render: (amount, record) => {
            return (
            <span>{(amount).toLocaleString('de-DE', { style: 'currency', currency: record.currency_ctr,maximumFractionDigits: 2})}</span>
            );
        },
      },
      {
        key:"percentage",
        title: "Weights %",
        dataIndex: "percentage",
        defaultSortOrder: 'descend',
        align: 'right',
        sorter: (a, b) => a.percentage - b.percentage,
        render: (percentage, record) => {
          if(record.percentage >= 0 )
            return (
            // <span style={{color: "black"}}>{price.toLocaleString('pt-BR')}</span>
            <span> {parseFloat(percentage).toFixed(2)} %</span>
            );
        },
    },
    ];

/*************************************************************************** */
  useEffect(() => {
    const getallInvestmentStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/portfolio-exchanges/get-cash-exchange', {
          userid:user._id,
          archive: false,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllInvestmentStats(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        // //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getallInvestmentStats();
  },[])

  useEffect(() => {
    const getallInvestmentStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/exchange-balances/get-exchange-balance', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllBalanceStats(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        // //message.error('Fetch Issue With account');
      }
    };  
  
    getallInvestmentStats();
  },[])

  //Analytics
  const totalTurnover = allBalanceStats
  .reduce((acc, transaction) => acc + transaction.difTodayValue_ctr, 0);

  const isPositive = totalTurnover >= 0;
  const formattedTurnover = totalTurnover.toLocaleString('de-DE', { style: 'currency', currency: user_currency });


/*************************************************************************** */
useEffect(() => {
  const getallStats = async (values) => {
    try{
      const user = JSON.parse(localStorage.getItem('user'))
      setLoading(true);
      const res = await axios.post('/portfolio-exchanges/get-all-exchange', {
        userid:user._id,
        // archive: false,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }       
      );
      setLoading(false);
      // setAllStats(res.data);
      // console.log(res.data);
    }catch (error){
      setLoading(false);
      // //message.error('Fetch Issue With account');
    }
  };  
  
  // Clean up the interval when the component unmounts
  return () => getallStats();
},[])

useEffect(() => {
  const getAllCashExchanges = async (values) => {
    try{
      const user = JSON.parse(localStorage.getItem('user'))
      setLoading(true);
      const res = await axios.post('/cash-exchanges/get-cash-exchange',
      {
        userid:user._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      setCashExchanges(res.data);
      // console.log(res.data);
    }catch (error){
      setLoading(false);
    }
  };  
  
  getAllCashExchanges();
},[])

const CountExchanges = cashExchanges.length;


const [chartData, setChartData]= useState([]);
useEffect(() => {
  const getAllExchangePortfolio = async (values) => {
    try{
      const user = JSON.parse(localStorage.getItem('user'))
      setLoading(true);
      const res = await axios.post('/exchange-performances/get-exchange-performance', {
        userid: user._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }       
      );
      setLoading(false);
      setAllExchangePortfolio(res.data);
      setChartData({
        options:{
        chart:{
          id: "basic-bar",
          type: 'area',
          stacked: false,
        },
        xaxis:{
          categories: res.data
          // Sort the data by 'dateSavingPerformance'
          .sort((a, b) => new Date(a._id.dateExchangePerformance) - new Date(b._id.dateExchangePerformance))
          // Map the sorted data to categories
          .map(item => {
            // Parse date string to Date object
            const date = new Date(item?._id.dateExchangePerformance);
            // Format date as YYYY-MM-DD
            return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
          })
        },
        stroke: {
          curve: "straight",
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        },
        series:[
        {
          name: "Retorno",
          data: res.data.map(item=> parseFloat(item?.today_value_ctr).toFixed(2)),
        },
        ],
      },);
      // console.log(res.data);
    }catch (error){
      setLoading(false);
      //message.error('Fetch Issue With account');
    }
  };  
  
  getAllExchangePortfolio();
},[])

  /*************************************************************************** */
  const [weightAssetSubject, setWeightAssetSubject]= useState([]);
  const [weightAssetMarks, setWeightAssetMarks]= useState([]);

  useEffect(() => {

  const sSubject=[];
  const sMarks=[];
  const getAllAssetWeights = async (values) => {
    try{
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'))
    const res = await axios.post('/portfolio-exchanges/get-asset-exchange-account',
    {
      userid:user._id,
    },
    {
      headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    }
    );
    setLoading(false);
    setAllStats(res.data);
    // console.log(res.data);

    for(let i=0; i< res.data.length; i++)
    {
    sSubject.push(res.data[i].symbol);
    sMarks.push(res.data[i].amount);
    }
    setWeightAssetSubject(sSubject);
    setWeightAssetMarks(sMarks);
    }catch (error){
    setLoading(false);
    // message.error('Fetch Issue With Sector');
    message.error('You are not Admin User');
    }
  };  
  getAllAssetWeights();
  },[])
  /*************************************************************************** */

  // console.log("user.investorProfile: ",user.investorProfile);
  // console.log("investor: ",investor);

  let range = {min: 1, max: 999999999}
  let delta = range.max - range.min

const codeSquare = Math.round(range.min + Math.random() * delta)

//NEW EXCHANGE
  const handleSubmitNewExchange = (values) => {
    // console.log('Submitted Email:', values);
    try{
    
      const user = JSON.parse(localStorage.getItem('user'))
  
      setLoading(true);

      if (brokerDetails === "Binance"){
      const result = axios.post('/exchanges/add-exchange', {
        ...values,
        brokerName:brokerDetails,
        codeSquare: codeSquare,
        assetType: "Cryptocurrencies",
        quantity: 1,
        userid:user._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      }
      setLoading(false);
      navigate('/ProcessingExchanges',
        {
          state:{
            exchangeName:values.investmentName,
            processing:"Portfolio em criação",
            navigateLink:"/MyInvestments",
            codeSquare: codeSquare
          }
        }
      );
    }catch (error){
      setLoading(false);
    }
  };

  const isNotEmptyArray = Array.isArray(distinctExchanges) && distinctExchanges.length > 0;
  const isBinanceVisible = distinctExchanges && distinctExchanges[0];

  return (
    <Layout>
      {loading && <Spinner/>}
      {/* Start Header */}
      <div className="accountpage">
        <div></div>
        <div><h1>My Exchanges</h1></div>
        <div></div>
      </div>

      <div style={{height: '100%' }}>
        <div style={{marginTop: "10px"}}>
        <div>
        <Tabs 
          defaultActiveKey="Portfolios" 
          onChange={handleTabChange}
          type="card"
        >

          {/* {isNotEmptyArray && ( */}
          <TabPane tab="Aggregator" key="Aggregate" style={{ marginTop:"25px"}} >
            <Form 
              layout="vertical"
              name="PortfolioAggForm"
              >
            <div>
              <h4 align="left">Performance {user_currency}</h4>
              <h4
                align="left"
                style={{ color: isPositive ? '#39A939' : '#FF3234', display: 'flex', alignItems: 'center' }}
              >
                {isPositive ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                {formattedTurnover}
              </h4>
              <h4 align="left">{allBalanceStats.difTodayValue_ctr}</h4>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
                {chartData && chartData?.series && (
                  <div className="chart-container area-chart">
                    <Chart
                      type="area"
                      align="center"
                      width={"100%"}
                      height={"400px"}
                      series={chartData.series}
                      options={{
                        ...chartData.options,
                        dataLabels: {
                          enabled: false, // Adiciona esta linha para ocultar os rótulos de dados
                        },
                        yaxis: {
                          labels: {
                            formatter: (value) => `${currencySymbol}${value.toFixed(2)}`,
                          },
                        },
                        tooltip: {
                          y: {
                            formatter: (value) => `${currencySymbol}${value.toFixed(2)}`,
                          },
                        },
                      }}
                    />
                  </div>
                )}
                <div className="chart-container donut-chart">
                  <Chart
                    type="donut"
                    align="center"
                    width="100%"
                    series={weightAssetMarks}
                    options={{
                      labels: weightAssetSubject,
                      noData: { text: "No Data" },
                      plotOptions: {
                        pie: {
                          donut: {
                            labels: {
                              show: true,
                              name: {
                                show: true,
                                formatter: function (val) {
                                  return val;
                                },
                              },
                              value: {
                                show: true,
                                formatter: function (val) {
                                  return `€${parseFloat(val).toFixed(2)}`;
                                },
                              },
                              total: {
                                show: true,
                                label: 'Total',
                                formatter: function (w) {
                                  return `€${w.globals.seriesTotals.reduce((a, b) => a + b, 0).toFixed(2)}`;
                                },
                              },
                            },
                          },
                        },
                      },
                      dataLabels: {
                        enabled: true,
                      },
                      legend: {
                        show: false
                      },
                      tooltip: {
                        y: {
                          formatter: (value) => `${currencySymbol}${value.toFixed(2)}`,
                        },
                      },
                      responsive: [
                        {
                          breakpoint: 281,
                          options: {
                            chart: {
                              width: '100%',
                              height: 150,
                            },
                          },
                        },
                        {
                          breakpoint: 361,
                          options: {
                            chart: {
                              width: '100%',
                              height: 230,
                            },
                          },
                        },
                        {
                          breakpoint: 481,
                          options: {
                            chart: {
                              width: '100%',
                              height: 300,
                            },
                          },
                        },
                        {
                          breakpoint: 769,
                          options: {
                            chart: {
                              width: '100%',
                              height: 275,
                            },
                          },
                        },
                        {
                          breakpoint: 768,
                          options: {
                            chart: {
                              width: '100%',
                              height: 300,
                            },
                          },
                        },
                        {
                          breakpoint: 1024,
                          options: {
                            chart: {
                              width: '100%',
                              height: 350,
                            },
                          },
                        },
                        {
                          breakpoint: 1200,
                          options: {
                            chart: {
                              width: '100%',
                              height: 350,
                            },
                          },
                        },
                        {
                          breakpoint: 2000,
                          options: {
                            chart: {
                              width: '100%',
                              height: 400,
                            },
                          },
                        },
                      ],
                    }}
                  />
                </div>
              </div>

              <style jsx>{`
                .chart-container {
                  flex: 1 1 100%;
                  max-width: 100%;
                }

                @media (min-width: 769px) {
                  .area-chart {
                    flex: 1 1 55%;
                    max-width: 55%;
                    min-width: 400px;
                  }

                  .donut-chart {
                    flex: 1 1 35%;
                    max-width: 35%;
                    min-width: 200px;
                  }
                }
              `}</style>
            </div>
            <hr />
              {/* <div><pre>{JSON.stringify(allStats, null, 2) }</pre></div>  */}
              <div className="content">
                <Table
                  size="small"
                  columns={columns}
                  dataSource={allStats}
                  pagination={{ position: ['bottomCenter'], defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: ['10', '25', '50']}}
                  scroll={{ x: 'max-content'}}
                  className="custom-table" // Apply custom CSS class for styling
                />
              </div>
            </Form>
          </TabPane>
          {/* )} */}

          <TabPane tab="Exchanges" key="Exchanges" style={{ marginTop:"25px"}} >
          {/* <div className="row" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}> */}
          {/* <Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
              <Chart
              align="center" 
              type="donut"
              width="100%"
              series={weightAssetMarks}                
              options={{
                responsive: [
                  {
                    breakpoint: 281, // iPhone SE width
                    options: {
                      chart: {
                        width: '100%',
                        height: 150 // Adjust height for iPhone SE
                      }
                    }
                  },
                  {
                    breakpoint: 361, // iPhone SE width
                    options: {
                      chart: {
                        width: '100%',
                        height: 230 // Adjust height for iPhone SE
                      }
                    }
                  },
                  {
                    breakpoint: 481, // iPhone SE width
                    options: {
                      chart: {
                        width: '100%',
                        height: 250 // Adjust height for iPhone SE
                      }
                    }
                  },
                  {
                    breakpoint: 769, // iPhone 12 Mini width
                    options: {
                      chart: {
                        width: '100%',
                        height: 275 // Adjust height for iPhone 12 Mini
                      }
                    }
                  },
                  {
                    breakpoint: 768, // iPhone 12, iPhone 14, and website width
                    options: {
                      chart: {
                        width: '100%',
                        height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                      }
                    }
                  },
                  {
                    breakpoint: 1024, // iPhone 12, iPhone 14, and website width
                    options: {
                      chart: {
                        width: '100%',
                        height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                      }
                    }
                  },
                  {
                    breakpoint: 1200, // iPhone 12, iPhone 14, and website width
                    options: {
                      chart: {
                        width: '100%',
                        height: 300 // Adjust height for iPhone 12, iPhone 14, and website
                      }
                    }
                  },
                  {
                    breakpoint: 2000, // iPhone 12, iPhone 14, and website width
                    options: {
                      chart: {
                        width: '100%',
                        height: 325 // Adjust height for iPhone 12, iPhone 14, and website
                      }
                    }
                  }
                ],
              labels: weightAssetSubject,
              noData: { text: "No Data" }, 
              plotOptions: {
                pie: {
                donut: {
                  labels: {
                  show: true,
                  name: {
                    formatter: function(val) {
                    return val
                    }
                  }
                  }
                }
                }
              },
              dataLabels: { enabled: true },
              legend: { show: false } // Hide legends
              }}
            />
          </Row>
          <h4 align="center">Valor dos Activos</h4>    
          <hr /> */}
            <Row gutter={[8,24]}

                style={{
                // height: 200,
                justifyContent: "center"
              }}>
                  {
                  allInvestmentStats.map((opts,i)=>
                    <Card
                    key={i} value={opts._id}
                    size="small"
                    hoverable
                    style={{ width: 400 , marginTop: 16, marginRight: "10px"}}
                    cover={[
                      <div align="center">
                        <br></br>
                        <div>
                          <br></br>
                          <img alt="example" src={MyWallet}/>
                        </div>
                        <div>
                          <br></br>
                          <h4 className="price">{opts.exchangeName.investmentName}</h4>
                        </div>
                      </div>
                    ]}
                    actions={[
                      <Tooltip title="Edit Exchange">
                      <SettingOutlined
                      key="setting" 
                      onClick={() => {
                        // console.log(opts);
                        navigate('/EditExchanges',
                        {
                          state:{
                            userID: opts.userid,
                            exchangeID:opts.exchangeName._id,
                            exchangeName:opts.exchangeName.investmentName,
                            exchangeDescription:opts.exchangeName.exchangeDescription,
                            brokerName:opts.exchangeName.brokerName,
                            currency:opts.exchangeName.currency,
                            frequency:opts.exchangeName.frequency,
                            goalName:opts.exchangeName.goalName,
                            codeSquare:opts.exchangeName.codeSquare,
                            status:opts.exchangeName.status,
                            apiKey:opts.exchangeName.apiKey,
                            keySecret:opts.exchangeName.keySecret,
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                      // <Tooltip title="Transactions">
                      // <UnorderedListOutlined
                      //   onClick={() => {
                      //     navigate('/OrderExchanges',
                      //     {
                      //       state:{
                      //         userID: opts.userid,
                      //         exchangeID:opts.exchangeName._id,
                      //         exchangeName:opts.exchangeName.investmentName,
                      //         currency:opts.exchangeName.currency,
                      //       }
                      //     });
                      //   }}
                      // />
                      // </Tooltip>,
                      <Tooltip title="Details Portfolio">
                      <PieChartOutlined
                        onClick={() => {
                          navigate('/PortfolioExchanges',
                          {
                            state:{
                              userID: opts.userid,
                              exchangeID:opts.exchangeName._id,
                              exchangeName:opts.exchangeName.investmentName,
                              currency:opts.exchangeName.currency,
                            }
                          });
                        }}
                      />
                      </Tooltip>,
                      <Tooltip title="Refrescar Portfolio">
                      <ReloadOutlined
                      onClick={() => {
                        // console.log(opts);
                        navigate('/ProcessingRefreshExchange',
                        {
                          state:{
                            exchangeID:opts.exchangeName._id,
                            exchangeName:opts.exchangeName.investmentName,
                            processing:"Refresh Exchange"
                          }
                        }
                      );
                      }}
                      />
                      </Tooltip>
                    ]}
                    >
                    <Meta
                      description={[
                            <div align="center" marginRight="500px">
                                {opts.exchangeName.statusApi
                                  ? 
                                  <Tooltip title="Active Trading API">
                                    <CheckCircleFilled className="mx-2" style={{color: "#39A939"}}/>
                                  </Tooltip>
                                  : 
                                  <Tooltip title="Trading API Disable">
                                  <ExclamationCircleFilled className="mx-2" style={{color: "#FF3234"}}/>
                                  </Tooltip>
                                }
                            </div>
                            ]}
                          />
                      <br></br>
                      <div className="additional"  align="center">
                      {/* <h6 className="price" >Valor da carteira: <span className="quantity">{opts.portfolio_exchange_value.toLocaleString('pt-BR')} {opts.currency} <br></br>( {opts.portfolio_exchange_value_ctr.toLocaleString('pt-BR')} {opts.currency_ctr} )</span></h6> */}
                      <h6 className="price" >Portfolio value: <span className="quantity">{(opts.portfolio_exchange_value).toLocaleString('de-DE', { style: 'currency', currency: opts.currency })} <br></br>( {(opts.portfolio_exchange_value_ctr).toLocaleString('de-DE', { style: 'currency', currency: user_currency })})</span></h6>
                      <hr></hr>
                      <h6 className="price" >Available money: <span className="quantity">{(opts.cash).toLocaleString('de-DE', { style: 'currency', currency: opts.currency })}<br></br>( {(opts.cash_ctr).toLocaleString('de-DE', { style: 'currency', currency: user_currency })} )</span></h6>
                      <h6 className="price" >Locked money: <span className="quantity">{(opts.locked).toLocaleString('de-DE', { style: 'currency', currency: opts.currency })}<br></br>( {(opts.locked_ctr).toLocaleString('de-DE', { style: 'currency', currency: user_currency })} ) </span></h6>
                      <hr></hr>
                      <p>Updated portfolio<br></br><span className="quantity">{moment(opts.date_refreshed).format("YYYY-MM-DD HH:mm")}</span></p>
                      </div>
                    </Card>)
                  }
            </Row>
          </TabPane>

          <TabPane tab="+ New Exchange" key="newPortfolio" style={{ marginTop:"25px"}} >
            <Form 
              layout="vertical"
              name="newPortfolioForm"
              onFinish={handleSubmitNewExchange}
              >

            <h5 style={{ marginBottom:30, marginTop:30, display: "flex", flexWrap: "wrap", justifyContent: "center" }}>Broker Name</h5>

            <div style={{ marginBottom:30, marginTop:30}}>
              <Radio.Group
                onChange={onChangeBroker}
                style={{ display: "flex", flexDirection: "column" }}
                required
              >
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                  <Radio.Button value="Binance" style={{ width: "100%", textAlign: "center", margin: "10px" }}>Binance</Radio.Button>
                  <Radio.Button disabled value="XTB" style={{ width: "100%", textAlign: "center", margin: "10px" }}>XTB</Radio.Button>
                  <Radio.Button disabled value="DeGiro" style={{ width: "100%", textAlign: "center", margin: "10px" }}>DeGiro</Radio.Button>
                  <Radio.Button disabled value="KuCoin" style={{ width: "100%", textAlign: "center", margin: "10px" }}>KuCoin</Radio.Button>
                  <Radio.Button disabled value="CoinBase" style={{ width: "100%", textAlign: "center", margin: "10px" }}>CoinBase</Radio.Button>
                </div>
              </Radio.Group>
              {!setBrokerDetails && <span style={{ color: 'red' }}>* Required field</span>}
            </div>

            <Form.Item label="Connection name" name="investmentName">
              <Input
                  style={{
                    width: '100%'
                  }}  
              type="text"
              />
            </Form.Item>

            <Form.Item label="Api Key" name="apiKey">
              <Input
                  style={{
                    width: '100%'
                  }}  
              type="text"
              />
            </Form.Item>

            <Form.Item label="Key Secret" name="keySecret">
              <Input 
              type="text"
              style={{
                width: '100%'
              }} 
              />
            </Form.Item>

            <Form.Item>
              <Button 
              style={{ width: "100%",marginTop: "20px"}} 
              type="primary" 
              htmlType="submit"
              >
                CREATE
              </Button>
              <Button
                style={{ width: "100%" , background: "white",color:"Blue", marginTop: 30, marginBottom: 30}}
                type="primary" 
                htmlType="submit"
                onClick={() => navigate('/')}
              >
                CANCEL
              </Button>
            </Form.Item>

            </Form>
          </TabPane>
        </Tabs>
      </div>
          {/* Content for the left border (80% of screen) */}
        </div>
      </div>

      {/* <div className="accountpagebuttonsAll">
        <Button
          shape="round"
          className="btn btn-primary btn-block"
          style={{ background: "blue", borderColor: "blue" }}
          onClick={() => navigate('/SavingAllTransactions')}
        >
          Todos os Movimentos
        </Button>
      </div> */}
      
      {/* <div><pre>{JSON.stringify(allSavingStats, null, 2) }</pre></div>  */}

      {/* <Row gutter={[8, 24]} style={{ justifyContent: "center" }}>
          <Card
              onClick={() => {
                  navigate('/NewSavings');
              }}
              size="small"
              hoverable
              style={{ width: "100%", marginTop: 16, marginRight: "10px" }}
              cover={[
                  <div align="center">
                      <br />
                      <div>
                          <div align="right" style={{ marginRight: "20px" }}>
                              {(() => {
                                  return (
                                      <div></div>
                                  )
                              })()}
                          </div>
                      </div>
                      <div>
                          <br />
                          <img alt="example" src={MySaving} />
                      </div>
                      <div>
                          <br />
                          <h4 className="price">Nova</h4>
                      </div>
                  </div>
              ]}
          >
              <Meta
                  description={[
                      <div align="center">
                          <h4 className="price">Poupança</h4>
                      </div>
                  ]}
              />
          </Card>
      </Row>
      <Row gutter={[8,24]}     
                style={{
                // height: 200,
                justifyContent: "center"
              }}>
                {
                  allSavingStats.map((opts,i)=>
                    <Card
                    key={i} value={opts._id}
                    size="small"
                    // hoverable
                    style={{ width: 560 , marginTop: 16, marginRight: "10px",backgroundColor:"#495057", color:"white"}}
                    cover={[
                        <div align="center">
                          <br />
                          <div align="center">
                            <div style={{justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ textAlign: "center", marginLeft: "20px", marginRight: "20px"}}>
                                    <h3><b>{opts.savingName.savingName}</b></h3>
                                </div>
                            </div>
                            <br></br>
                            <div style={{justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ textAlign: "center", marginLeft: "20px", marginRight: "20px"}}>
                                    <h5><b>{opts.productName.productNamePT}</b></h5>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "40px" }}>
                              <div style={{ textAlign: "left", marginLeft:"20px" }}>
                              <h5 style={{ textAlign: "center", marginLeft:"20px"}}>{(opts.returnToTarget).toLocaleString('de-DE', { style: 'currency', currency: user_currency })}<br/>
                              <br></br>
                              <span style={{ color: "#adb5bd" }}>Saldo Total</span></h5>
                              </div>
                              <div style={{ textAlign: "right" , marginRight:"20px" }}>
                              <h5 style={{ textAlign: "center", marginLeft:"20px"}}>{(opts.monthlyAmount).toLocaleString('de-DE', { style: 'currency', currency: user_currency })}<br/>
                              <br></br>
                              <span style={{ color: "#adb5bd" }}>Entrega Mensal</span></h5>
                              </div>
                            </div>
                            <br></br>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div style={{ textAlign: "left", marginLeft:"20px" }}>
                                <h5 style={{ textAlign: "center", marginLeft:"20px"}}>{(opts.returnAmount).toLocaleString('de-DE', { style: 'currency', currency: user_currency })}<br/>
                                <br></br>
                                <span style={{ color: "#adb5bd" }}>Ganhos</span></h5>
                              </div>

                              <div style={{ textAlign: "right" , marginRight:"20px" }}>
                                <h5 style={{ textAlign: "center", marginLeft:"20px" }}>{parseFloat(opts.returnPercentage*100).toFixed(3)} %<br/>
                                <br></br>
                                <span style={{ color: "#adb5bd" }}> Rentabilidade</span></h5>
                              </div>
                            </div>
                            <br></br>
                            <div style={{justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ textAlign: "center", marginLeft: "20px" }}>
                                    <h5><b>{moment(opts.targetDate).format("YYYY-MM-DD")}</b>
                                    <br></br>
                                <span style={{ color: "#adb5bd" }}> Data do Objectivo</span></h5>
                                </div>
                            </div>
                        </div>
                      </div>
                    ]}
                    actions={[
                      <Tooltip title="Entregas">
                      <UploadOutlined key="setting"
                      // onClick={() => {
                      //   console.log(opts);
                      //   navigate('/EditInsurances',
                      //   {
                      //   });
                      // }}
                      />
                      </Tooltip>,

                      <Tooltip title="Resgates">
                      <DownloadOutlined key="setting"
                      // onClick={() => {
                      //   console.log(opts);
                      //   navigate('/EditInsurances',
                      //   {
                      //   });
                      // }}
                      />
                      </Tooltip>,

                      <Tooltip title="Evolução">
                      <StockOutlined key="setting"
                      onClick={() => {
                        console.log(opts);
                        navigate('/SavingPortfolioPerformance',
                        {
                          state:{
                            savingNameID: opts.savingName._id,
                            savingName: opts.savingName.savingName,
                            savingProcessID: opts.savingProcessId,
                            productName: opts.productName.productNamePT,     
                          }
                        });
                      }}
                      />
                      </Tooltip>,

                      <Tooltip title="Portfolio">
                      <PieChartOutlined
                      onClick={() => {
                        console.log("opts:",opts);
                        navigate('/SavingPortfolio',
                        {
                          state:{
                            savingNameID: opts.savingName._id,
                            savingName: opts.savingName.savingName,
                            savingProcessID: opts.savingProcessId,
                            productName:  opts.productName.productNamePT,     
                          }
                        });
                      }}
                      />
                      </Tooltip>,
                      
                      <Tooltip title="Editar">
                      <EditOutlined
                      onClick={() => {
                        console.log("opts",opts);
                        navigate('/EditSavings',
                        {
                          state:{
                          savingNameID: opts.savingName._id,
                          savingName: opts.savingName.savingName,
                          savingProcessID:  opts.savingProcessId,
                          categoryNameUpdate:  opts.savingName.categoryName,
                          productNameUpdate:  opts.productName.productNamePT,
                          agentNameUpdate:  opts.agentName,
                          IBANUpdate:  opts.savingName.IBAN,
                          monthlyAmountUpdate:  opts.savingName.monthlyAmount,
                          dayPaymentUpdate:  opts.savingName.dayPayment,
                          isDepositActiveUpdate:  opts.savingName.isDepositActive,
                          takeProfitUpdate:  opts.savingName.takeProfit,
                          percentageTPUpdate:  opts.savingName.percentageTP,
                          stopLossUpdate:  opts.savingName.stopLoss,
                          percentageSLUpdate:  opts.savingName.percentageSL,
                          }  
                        });
                      }}
                      />
                      </Tooltip>
                    ]}
                    >
                    <div 
                    style={{ justifyContent: "space-between"}}
                    >
                      <Progress
                        percent={opts.percentageToTarget*100}
                        showInfo={false}
                        trailColor="#f5f5f5" // Background color
                      />
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div><h5>{(opts.percentageToTarget * 100).toFixed(2)}%</h5></div>
                        <div><h5>{(opts.targetValue).toLocaleString('de-DE', { style: 'currency', currency: user_currency })}</h5></div>
                      </div>
                    </div>
                    </Card>)
                  }
        </Row> */}
    </Layout>
  );
};

export default MyInvestments;