import React,{useState,useEffect} from 'react';
import {Card,Row,Col, Tooltip} from 'antd';
import Layout from "./../components/Layout/Layout";
import MyInvestment from "./../components/Assets/investment.png";
import MySaving from "./../components/Assets/robot.png";
import MyClients from "./../components/Assets/people.png";
import MyVisualData from "./../components/Assets/visual-data.png";

import moment from 'moment';

import Spinner from "../components/Spinner";
import {useNavigate} from "react-router-dom";

import axios from 'axios';
import { ArrowDownOutlined, ArrowUpOutlined, ReloadOutlined } from '@ant-design/icons';

const HomePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { Meta } = Card;

  const [allAccountStats, setAllAccountStats] = useState([]);
  const [allAccountExchangeStats, setAllAccountExchangeStats] = useState([]);
  const [allAccountAgentStats, setAllAccountAgentStats] = useState([]);

  const [allSavingStats, setAllSavingStats] = useState([]);
  const [cashExchanges, setCashExchanges] = useState([]);
  const [allBalanceStats, setAllBalanceStats] = useState([]);

  //loading Apis
  const [loading,setLoading] = useState(false);

  const [loginUser, setLoginUser] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const user_agentName = loginUser.agentName
  // console.log("user_agent:",user_agent)
  const profileType = loginUser.profileType
  const user_currency = loginUser.currency

  // Accounts ADMIN-------------------------------------------------------------------------------------

  useEffect(() => {
    const getAllAccountStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/savingbalances/get-allsavingbalanceadmin', 
        {
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllAccountStats(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    getAllAccountStats();
  },[])

  //Analytics
  const totalAccountBalance= allAccountStats
  .reduce((acc, transaction) => acc + transaction.today_value, 0);

  // Calculate the number of distinct NIFs
  const distinctClients = [...new Set(allAccountStats.map(transaction => transaction.userid))];
  const numberOfDistinctClients = distinctClients.length;
  
  const CountAccountBalance= allAccountStats.length;


  // EXCHANGES ADMINISTRATION
  useEffect(() => {
    const getallInvestmentStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/exchange-balances/get-allexchangesadmin', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllAccountExchangeStats(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        // //message.error('Fetch Issue With account');
      }
    };  
  
    getallInvestmentStats();
  },[])

  //Analytics
  const totalExchangeTurnover = allAccountExchangeStats
  .reduce((acc, transaction) => acc + transaction.today_value, 0);

  const CountAllExchanges = allAccountExchangeStats.length;

  //Analytics
  const totalDifAdminTurnover = allAccountExchangeStats
  .reduce((acc, transaction) => acc + transaction.difTodayValue, 0);

  const isPositiveExchangeAdmin = totalDifAdminTurnover >= 0;

  // Accounts USER-------------------------------------------------------------------------------------

  useEffect(() => {
    const getallSavingStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/savingbalances/get-allsavingbalance', {
          userid:user._id,
          filterOption:"open",
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllSavingStats(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        //message.error('Fetch Issue With account');
      }
    };  
    
    // Clean up the interval when the component unmounts
    getallSavingStats();
  },[])

  //Analytics
  const totalSavingBalance= allSavingStats
  .reduce((acc, transaction) => acc + transaction.today_value, 0);

  const totalReturnDailyBalance= allSavingStats
  .reduce((acc, transaction) => acc + transaction.diffDaily, 0);

  const isPositiveReturnDaily = totalReturnDailyBalance >= 0;
  
  const CountSavingBalance= allSavingStats
  .filter((transaction) => transaction.savingName.closePortfolio === false)
  .length
  ;

  // Calculate the maximum date from allSavingStats.dateSavingPerformance
  const maxDatePortfolio = allSavingStats
  .reduce((latest, transaction) => {
    const currentDate = new Date(transaction.dateSavingPerformance); // Ensure this is a date string or timestamp
    return (currentDate > latest) ? currentDate : latest;
  }, new Date(0)); // Initialize with the earliest possible date

  useEffect(() => {
    const getallInvestmentStats = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/exchange-balances/get-exchange-balance', {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }       
        );
        setLoading(false);
        setAllBalanceStats(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
        // //message.error('Fetch Issue With account');
      }
    };  
  
    getallInvestmentStats();
  },[])

  //Analytics
  const totalTurnover = allBalanceStats
  .reduce((acc, transaction) => acc + transaction.difTodayValue, 0);

  const totalValueTurnover = allBalanceStats
  .reduce((acc, transaction) => acc + transaction.today_value, 0);

  const isPositive = totalTurnover >= 0;

  useEffect(() => {
    const getAllCashExchanges = async (values) => {
      try{
        const user = JSON.parse(localStorage.getItem('user'))
        setLoading(true);
        const res = await axios.post('/cash-exchanges/get-cash-exchange',
        {
          userid:user._id,
        },
        {
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
        }
        );
        setLoading(false);
        setCashExchanges(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
      }
    };  
    
    getAllCashExchanges();
  },[])

  const CountExchanges = cashExchanges.length;

  const totalCashTurnover = cashExchanges
  .reduce((acc, transaction) => acc + transaction.cash, 0);

  const totalDateUpgradeTurnover = cashExchanges
  .reduce((acc, transaction) => acc + transaction.date_refreshed, 0);

    // Accounts AGENT-------------------------------------------------------------------------------------

    useEffect(() => {
      const getAllAccountAgentStats = async (values) => {
        try{
          const user = JSON.parse(localStorage.getItem('user'))
          setLoading(true);
          const res = await axios.post('/savingbalances/get-allsavingbalanceagent', 
          {
            agentName: user.agentName,
          },
          {
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
          }       
          );
          setLoading(false);
          setAllAccountAgentStats(res.data);
          // console.log(res.data);
        }catch (error){
          setLoading(false);
          //message.error('Fetch Issue With account');
        }
      };  
      
      getAllAccountAgentStats();
    },[])
  
    //Analytics
    const totalAccountBalanceAgent= allAccountAgentStats
    .reduce((acc, transaction) => acc + transaction.today_value, 0);
  
    // Calculate the number of distinct NIFs
    const distinctClientsAgent = [...new Set(allAccountAgentStats.map(transaction => transaction.userid))];
    const numberOfDistinctClientsAgent = distinctClientsAgent.length;
    
    const CountAccountBalanceAgent= allAccountAgentStats.length;

  //***************************RENDER**************************************************************************/

  return (
    
    <Layout>

      {loading && <Spinner/>}

      {/* <div><pre>{JSON.stringify(allSavingStats, null, 2) }</pre></div> */}

      {/* LAYOUT ADMIN */}
      {profileType === 9 && (
      <div className="content">
        <Row gutter={[8,24]}
            style={{
            justifyContent: "center"
          }}>
            <Col xs={60} sm={36} md={26} lg={24}>
              <Card
              onClick={() => {
                navigate('/MySavingsAdmin');
              }}
              size="large"
              hoverable
              style={{ width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
              cover={[
                <div align="center">
                  <div>
                    <br></br>
                    <img alt="example" src={MySaving} />
                  </div>
                  <div>
                    <br></br>
                    <h4 className="price" style={{marginLeft: 55,marginRight:55}}>Cestos de Criptomoedas</h4>
                  </div>
                </div>
              ]}
              >
              <Meta
                description={[
                      <div align="center">
                          <h4 className="price">{CountAccountBalance} contas</h4>
                      </div>
                      ]}
                    />
                <br></br>
              </Card>
            </Col>

            <Col xs={60} sm={36} md={26} lg={24}>
              <Card
              onClick={() => {
                navigate('/MyClients');
              }}
              size="large"
              hoverable
              style={{width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
              cover={[
                <div align="center">
                  <div>
                    <br></br>
                    <img alt="example" src={MyClients} />
                  </div>
                  <div>
                    <br></br>
                    <h4 className="price">Meus Clientes</h4>
                  </div>
                </div>
              ]}
              >
              <Meta
                description={[
                      <div align="center">
                          <h4 className="price">{numberOfDistinctClients} clientes</h4>
                      </div>
                      ]}
                    />
                <br></br>
                <div className="additional"  align="center">
                  <h4 className="price">Saldo: <span className="quantity">{(totalAccountBalance).toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}</span></h4>
                </div>
              </Card>
            </Col>
            
            <Col xs={60} sm={36} md={26} lg={24}>
              <Card
              // onClick={() => {
              //   navigate('/MyInvestings');
              // }}
              size="large"
              hoverable
              style={{ width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
              cover={[
                <div align="center">
                  <div>
                    <br></br>
                    <img alt="example" src={MyInvestment} />
                  </div>
                  <div>
                    <br></br>
                    <h4 className="price">Investimentos</h4>
                  </div>
                </div>
              ]}
              actions={[
                    <Tooltip title="Refrescar Exchanges">
                    <ReloadOutlined
                    onClick={() => {
                      navigate('/ProcessingRefreshAllExchange');
                    }}
                    />
                    </Tooltip>
                  ]}
              >
              <Meta
                description={[
                      <div align="center">
                          <h4 className="price">{CountAllExchanges} Exchanges</h4>
                      </div>
                      ]}
                    />
                <br></br>
                <div className="additional" align="center">
                <h4 className="price">
                Saldo: <span className="quantity">{totalExchangeTurnover.toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}</span>
                </h4>
                {!isNaN(totalDifAdminTurnover) && (
                  <h4
                    style={{ color: isPositiveExchangeAdmin ? '#39A939' : '#FF3234' }}
                    className="price"
                  >
                    Hoje: <span className="quantity">{isPositiveExchangeAdmin ? <ArrowUpOutlined /> : <ArrowDownOutlined />} {totalDifAdminTurnover.toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}</span>
                  </h4>
                )}
              </div>

              
              </Card>
            </Col>
          </Row>
      </div>
    )}

    {/* Cliente */}
    {profileType === 0 && (
      <div className="content">
        <Row gutter={[8,24]}
            style={{
            justifyContent: "center"
          }}>
            {CountExchanges !== 0 && (
              <Col xs={60} sm={36} md={26} lg={24}>
                <Card
                  onClick={() => {
                    navigate('/MySavings');
                  }}
                  size="large"
                  hoverable
                  style={{ width: "100%", height: "100%", marginTop: 16}}
                  cover={(
                    <div align="center">
                      <div>
                        <br></br>
                        <img alt="example" src={MySaving} />
                      </div>
                      <div>
                        <br></br>
                        <h4 className="price">My Crypto Baskets</h4>
                      </div>
                    </div>
                  )}
                >
                  <div className="additional" align="center">
                    <h4 className="price" style={{marginLeft: 14,marginRight: 14}}>
                      Balance: 
                      <span className="quantity">
                        {(totalSavingBalance).toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}
                      </span>
                    </h4>
                    <h4
                      style={{ color: isPositiveReturnDaily ? '#39A939' : '#FF3234' }}
                      className="price"
                    >
                      Today: <span className="quantity">{isPositiveReturnDaily ? <ArrowUpOutlined /> : <ArrowDownOutlined />} {totalReturnDailyBalance.toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}</span>
                    </h4>
                    <hr/>
                    <div align="center">
                          <h4 className="price">{CountSavingBalance} accounts</h4>
                          {/* <h4 className="price">{CountAccountBalance} clientes</h4> */}
                    </div>
                    <hr/>
                    <p>
                      <p>Updated portfolio<br></br><span className="quantity">{moment([0].dateExchangePerformance).format("YYYY-MM-DD HH:mm")}</span></p>
                    </p>
                  </div>
                  <Meta
                  />
                </Card>
              </Col>
            )}

            <Col xs={60} sm={36} md={26} lg={24}>
            <Card
            onClick={() => {
              navigate('/MyInvestments');
            }}
            size="large"
            hoverable
            style={{marginTop: 16,marginRight: "10px",marginLeft: "10px"}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyInvestment} />
                </div>
                <div>
                  <br></br>
                  <h4 className="price"
                  style={{ marginRight: "20px",marginLeft: "20px"}}
                  >My Exchanges</h4>
                </div>
              </div>
            ]}
            >
            <div className="additional" align="center">
              <h4 className="price">
                Balance: <span className="quantity">{totalValueTurnover.toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}</span>
              </h4>
              {!isNaN(totalTurnover) && (
                <h4
                  style={{ color: isPositive ? '#39A939' : '#FF3234' }}
                  className="price"
                >
                  Today: <span className="quantity">{isPositive ? <ArrowUpOutlined /> : <ArrowDownOutlined />} {totalTurnover.toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}</span>
                </h4>
              )}
              <hr/>
              {!isNaN(totalTurnover) && (
                <h5 className="price">
                  Cash Available: <span className="quantity"> {totalCashTurnover.toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}</span>
                </h5>
              )}
              <hr/>
              {!isNaN(totalTurnover) && (
                <p>
                  <p>Updated portfolio<br></br><span className="quantity">{moment(totalDateUpgradeTurnover).format("YYYY-MM-DD HH:mm")}</span></p>
                </p>
              )}
            </div>
            <br></br>
            <Meta
                  />
            </Card>
            </Col>
          </Row>
      </div>
    )}
    
    {/* LAYOUT AGENT */}
    {profileType === 1 && (
      <div className="content">
      <Row gutter={[8,24]}
          style={{
          justifyContent: "center"
        }}>
          <Col xs={60} sm={36} md={26} lg={24}>
            <Card
            onClick={() => {
              navigate('/MySavingsAgent');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MySaving} />
                </div>
                <div>
                  <br></br>
                  <h4 className="price" style={{marginLeft: 55,marginRight:55}}>Poupanças</h4>
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    <div align="center">
                        <h4 className="price">{CountAccountBalanceAgent} contas</h4>
                    </div>
                    ]}
                  />
              <br></br>
              {/* <div className="additional"  align="center">
                <h4 className="price">Saldo: <span className="quantity">{(totalAccountBalance).toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}</span></h4>
              </div> */}
            </Card>
          </Col>

          <Col xs={60} sm={36} md={26} lg={24}>
            <Card
            onClick={() => {
              navigate('/MyClientsAgent');
            }}
            size="large"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16,marginRight: "10px"}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyClients} />
                </div>
                <div>
                  <br></br>
                  <h4 className="price">Meus Clientes</h4>
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    <div align="center">
                        {/* <h4 className="price">{CountAccountBalance} contas</h4> */}
                        <h4 className="price">{numberOfDistinctClientsAgent} clientes</h4>
                    </div>
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">Saldo: <span className="quantity">{(totalAccountBalanceAgent).toLocaleString('de-DE', { style: 'currency', currency: 'USD' })}</span></h4>
              </div>
            </Card>
          </Col>

        </Row>
    </div>
    )}
    
    </Layout>
    
  );
};

export default HomePage;