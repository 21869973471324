import React,{useState,useEffect} from 'react'
import {Link,useNavigate} from "react-router-dom";
import { Carousel,Button,message,Space,Row} from "antd";
import { FaHome, FaEnvelope, FaBook, FaAddressBook, FaCog, FaSignOutAlt, FaUserShield, FaTachometerAlt } from 'react-icons/fa';

import MyLogo from "../Assets/bi-capital.jpg";

// import axios from "axios";
// import { LogoutOutlined } from '@ant-design/icons';

const Navbar = () => {

  const [loginUser, setLoginUser] = useState('')
  const navigate = useNavigate();

  const [loading,setLoading] = useState(false);

  const [allAccount, setAllAccount] = useState([]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const user = JSON.parse(localStorage.getItem('user'))
  const profileType = user.profileType

    // //Handler Fund Family DropList ****************************************************************************************
    // useEffect(() => {
    //   const getAllAccounts = async (values) => {
    //     try{
    //       const user = JSON.parse(localStorage.getItem('user'))
    //       setLoading(true);
    //       const res = await axios.post('/accounts/get-account', {
    //         userid:user._id,
    //         status: true,
    //         isCreditCard: "all"
    //       },
    //       {
    //         headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
    //       }
    //       );
    //       setLoading(false);
    //       setAllAccount(res.data);
    //       console.log(res.data);
    //     }catch (error){
    //       setLoading(false);
    //       //message.error('Fetch Issue With sector');
    //       // message.error('You are not Admin User');
    //     }
    //   };  
      
    // // Clean up the interval when the component unmounts
    // return () => getAllAccounts();
    
    // },[])
  
      
  // const logoutHandler = () => {
  //   localStorage.removeItem('user');
  //   message.success("Logout Successfully");
  //   navigate('/login');
  // }
  
  // const NewExpenseHandler = () => {
  //   navigate('/NewExpenses');
  // }

  // const NewIncomeHandler = () => {
  //   navigate('/NewIncomes');
  // }

  // const totalAccounts = allAccount.length;

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  
  const logoutHandler = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('investorProfile');
    message.success("Logout Successfully");
    navigate('/login');
  }

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <a href="/"><img style={{ width: '32px', height: '32px', marginRight: '8px'}} alt="example" src={MyLogo} />BIT ADVISOR</a>
        <button className="navbar-toggle" onClick={toggleNavbar}>
          ☰
        </button>
      </div>
      <div className={`navbar-menu ${isOpen ? 'is-open' : ''}`}>
        <a href="/" className="navbar-item">
          <FaHome className="navbar-icon" /> Home
        </a>
        {profileType === 9 && (
        <a href="/ExplorerSettings" className="navbar-item">
          <FaUserShield className="navbar-icon" /> Admin
        </a>
        )}
        {profileType === 9 && (
        <a href="/" className="navbar-item">
          <FaTachometerAlt className="navbar-icon" /> Dashboard
        </a>
        )}
        <a href="/MyEmails" className="navbar-item">
          <FaEnvelope className="navbar-icon" /> MailBox
        </a>
        <a href="/FinanceLiteracy" className="navbar-item">
          <FaBook className="navbar-icon" /> Financial Literacy
        </a>
        <a href="/MyContacts" className="navbar-item">
          <FaAddressBook className="navbar-icon" /> Contacts
        </a>
        <a href="/MySettings" className="navbar-item">
          <FaCog className="navbar-icon" /> More
        </a>
        <a className="navbar-item" onClick={logoutHandler}>
          <FaSignOutAlt className="navbar-icon" /> Logout
        </a>
      </div>
    </nav>
  )
}

export default Navbar;