import React,{useState,useEffect} from 'react';
import {Card,Row,Col} from 'antd';
import Layout from "../components/Layout/Layout";
import MyBank from "./../components/Assets/bank.png";
import Spinner from "../components/Spinner";
import {useNavigate} from "react-router-dom";

const ExplorerSettings = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { Meta } = Card;

  //loading Apis
  const [loading,setLoading] = useState(false);

  const [loginUser, setLoginUser] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const user = JSON.parse(localStorage.getItem('user'))
  const profileType = user.profileType

  //***************************RENDER**************************************************************************/

  return (
    
    <Layout>

      {loading && <Spinner/>}
      {/* Start Header */}
      <div className="accountpage">
        <div></div>
        <div><h4>SISTEMA</h4></div>
        <div></div>
      </div>

      <div className="content">
        <Row gutter={[8,24]}
            style={{
            justifyContent: "center"
          }}>
          
            {/* subscription package view card */}
            {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Users');
            }}
            size="medium"
            //disabled  // Add the disabled prop
            hoverable
            style={{  width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">UTILIZADORES</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/MyAgents');
            }}
            size="medium"
            //disabled  // Add the disabled prop
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">AGENTES</h4>
              </div>
            </Card>
            </Col>
            )}
            
            {/* subscription package view card */}
            {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/UsersAgents');
            }}
            size="medium"
            //disabled  // Add the disabled prop
            hoverable
            style={{  width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">AGENTES UTILIZADORES</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/Categories');
            }}
            size="medium"
            //disabled  // Add the disabled prop
            hoverable
            style={{  width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">CATEGORIAS</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {/* {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/SubCategories');
            }}
            size="medium"
            hoverable
            style={{  width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">SUBCATEGORIAS</h4>
              </div>
            </Card>
            </Col>
            )} */}

            {/* subscription package view card */}
            {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/InvestorRiskProfiles');
            }}
            size="medium"
            //disabled  // Add the disabled prop
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">PERFIL DE RISCO</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/CategoryAssets');
            }}
            size="medium"
            //disabled  // Add the disabled prop
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">CLASSE DE ATIVOS</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {/* {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/MyAssets');
            }}
            size="medium"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">ATIVOS</h4>
              </div>
            </Card>
            </Col>
            )} */}

            {/* subscription package view card */}
            {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/CryptoAssets');
            }}
            size="medium"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">CRIPTOMOEDAS</h4>
              </div>
            </Card>
            </Col>
            )}

                        {/* subscription package view card */}
                        {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/CurrencyAssets');
            }}
            size="medium"
            //disabled  // Add the disabled prop
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">MOEDAS CAMBIAIS</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/MyProducts');
            }}
            size="medium"
            //disabled  // Add the disabled prop
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16 }}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">PRODUTOS</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {/* {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/AssetPrices');
            }}
            size="medium"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">COTAÇÃO DOS ATIVOS</h4>
              </div>
            </Card>
            </Col>
            )} */}

            {/* subscription package view card */}
            {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/CurrencyRates');
            }}
            size="medium"
            //disabled  // Add the disabled prop
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">COTAÇÃO DAS MOEDAS CAMBIAIS</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/CryptoPrices');
            }}
            size="medium"
            //disabled  // Add the disabled prop
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">COTAÇÃO CRIPTOMOEDAS</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {/* {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/ProductPrices');
            }}
            size="medium"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">COTAÇÃO DOS PRODUTOS</h4>
              </div>
            </Card>
            </Col>
            )} */}

            {/* subscription package view card */}
            {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/CryptoRisks');
            }}
            size="medium"
            //disabled  // Add the disabled prop
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">RISCO CRIPTOMOEDAS</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/CryptoSmarts');
            }}
            size="medium"
            //disabled  // Add the disabled prop
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">SMART-ADVISOR CRIPTOMOEDAS</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              navigate('/FinanceLiteracySetup');
            }}
            size="medium"
            //disabled  // Add the disabled prop
            hoverable
            // style={{ width: "100%", height: "100%", marginTop: 16, cursor: "not-allowed" }}
            style={{ width: "100%", height: "100%", marginTop: 16}}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">LITERACIA FINANCEIRA</h4>
              </div>
            </Card>
            </Col>
            )}

            {/* subscription package view card */}
            {(profileType === 9) && (
            <Col xs={24} sm={12} md={12} lg={8}>
            <Card
            onClick={() => {
              // navigate('/Users');
            }}
            size="medium"
            hoverable
            style={{ width: "100%", height: "100%", marginTop: 16, cursor: "not-allowed" }}
            cover={[
              <div align="center">
                <div>
                  <br></br>
                  <img alt="example" src={MyBank} />
                </div>
              </div>
            ]}
            >
            <Meta
              description={[
                    ]}
                  />
              <br></br>
              <div className="additional"  align="center">
                <h4 className="price">FAQ'S</h4>
              </div>
            </Card>
            </Col>
            )}

        </Row>
      </div>

    </Layout>
  );
};

export default ExplorerSettings;