import React,{useState,useEffect} from 'react';
import {Steps,Form,Input,Button,Switch,message,Select,Row,Col,DatePicker} from 'antd';
import { CheckCircleOutlined, LikeOutlined, LoginOutlined, ProfileOutlined, ShopOutlined} from '@ant-design/icons';
import {useNavigate, Link} from "react-router-dom";
import moment from 'moment';
import axios from "axios";

import Spinner from "../components/Spinner";

import { FaCheckCircle } from 'react-icons/fa';

const RegisterAccountPage = () => {

  const { TextArea } = Input;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  //loading Apis
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState('')

  const [allAgent, setAllAgent] = useState([]);

  // const { TextArea } = Input;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user){
      setLoginUser(user)
    }
  },[])

  const [current, setCurrent] = useState(0);
  const [profileDetails, setProfileDetails] = useState(null);
  const [jobDetails, setJobDetails] = useState(null);
  const [reviewDetails, setReviewDetails] = useState(null);
  const [loginDetails, setLoginDetails] = useState(null);
  const [agentDetails, setAgentDetails] = useState(null);
 
  const onFinishProfileForm=(values) => {
    setProfileDetails(values);
    setCurrent(1);
  }

  const onFinishJobForm=(values) => {
    setJobDetails(values);
    setCurrent(2);
  }

  const onFinishReviewForm=(values) => {
    setReviewDetails(values);
    setCurrent(3);
  }

  const onFinishLoginForm=(values) => {
    setLoginDetails(values);
    setCurrent(4);
  }

  const onFinishAgentForm=(values) => {
    setAgentDetails(values);
    setCurrent(5);
  }

  const forms =[
    <ProfileForm 
      onFinish={onFinishProfileForm} 
      initialValues={profileDetails}/>,
    <JobForm 
      onFinish={onFinishJobForm} 
      initialValues={jobDetails}/>,
    <ReviewForm
      onFinish={onFinishReviewForm}
      initialValues={reviewDetails}/>,
    <LoginForm 
      onFinish={onFinishLoginForm} 
      initialValues={loginDetails}/>,
    <AgenteForm 
      onFinish={onFinishAgentForm} 
      initialValues={agentDetails}/>,
    <FinishForm />
  ]

  const isStepDisabled=(stepnumber)=>{
    if(stepnumber===0){
      return false
    }
    if(stepnumber===1){
      return profileDetails === null
    }
    if(stepnumber===2){
      return profileDetails === null || jobDetails === null
    }
    if(stepnumber===3){
      return profileDetails === null || jobDetails === null || reviewDetails === null
    }
    if(stepnumber===4){
      return profileDetails === null || jobDetails === null || reviewDetails === null || loginDetails === null
    }
    if(stepnumber===5){
      return profileDetails === null || jobDetails === null || reviewDetails === null || loginDetails === null || agentDetails === null
    }
  }

  //Handler Asset Class DropList ****************************************************************************************
  useEffect(() => {
    const getAllAgent = async (values) => {
      try{
        setLoading(true);
        const res = await axios.post('/myagents/get-allAgent', {
        },
        );
        setLoading(false);
        setAllAgent(res.data);
        // console.log(res.data);
      }catch (error){
        setLoading(false);
      }
    };  
    
    // Clean up the interval when the component unmounts
    return () => getAllAgent();
  },[])

  const submitHandler = async (values) => {
    try{
      setLoading(true);
      await axios.post('/users/register',
      {
      //STEP 1     
      firstName:profileDetails.firstName,
      lastName:profileDetails.lastName,
      nif:profileDetails.nif,
      currency:profileDetails.currency,
      birthdayDate:moment(profileDetails.birthdayDate).format('YYYY-MM-DD'),
      countryCitizenship:profileDetails.countryCitizenship,
      phoneNumber:profileDetails.phoneNumber,
      
      //STEP 3
      employmentStatus:jobDetails.employmentStatus,
      ocuppationWork:jobDetails.ocuppationWork,

      //STEP 5
      email:loginDetails.email,
      password:loginDetails.password,
      isPEP:loginDetails.isPEP,
      isCorporateControl:loginDetails.isCorporateControl,
      acceptPrivacy:loginDetails.acceptPrivacy,
      acceptMarketing:loginDetails.acceptMarketing,

      //STEP 6
      agentName:agentDetails.agentName,

      })
      message.success('Successful registration');
      setLoading(false);
      navigate('/Login')
    }catch (error){
      setLoading(false);
      message.error('Something went wrong');
    }
  }

  const allCountries=[
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo (Republic of the)",
    "Congo (Democratic Republic of the)",
    "Cook Islands",
    "Costa Rica",
    "Côte d'Ivoire",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Vatican City State",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (Democratic People's Republic of)",
    "Korea (Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia (the former Yugoslav Republic of)",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Réunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan, Province of China",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom of Great Britain and Northern Ireland",
    "United States Minor Outlying Islands",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Vietnam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe"    
  ];

  function ProfileForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "100%"}} onFinish={onFinish} initialValues={initialValues} layout="vertical">
        <h4 align="center">Tell me about yourself</h4>
        <hr/>
        <br/>
        <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="First name"
            name="firstName"
            rules={[{ required: true, message: "Please enter your first name" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Last name"
            name="lastName"
            rules={[{ required: true, message: "Please enter your last name" }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      {/* <Form.Item label="Nome" name={"name"} 
        rules={[{
          required:true, 
          message:"Por favor, insira seu nome"
        }]}
      >
        <Input />
      </Form.Item> */}
      <Form.Item label="Fiscal number" name={"nif"} 
        rules={[{
          required:true, 
          message:"Please enter your tax number"
        }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Currency" name="currency">
          <Select
              style={{
                width: '100%',
              }} 
          >
            <Select.Option value="EUR">EUR</Select.Option>
            <Select.Option value="USD">USD</Select.Option>
            <Select.Option value="BRL">BRL</Select.Option>
            <Select.Option value="CHF">CHF</Select.Option>
            <Select.Option value="GBP">GBP</Select.Option>
          </Select>
      </Form.Item>
      
      {/* <Form.Item label="Birth date" name="birthdayDate"
          rules={[
              { required: true, whitespace:true, message: 'Enter date of birth!' },
              ]}>
          <Input type="date"/>
      </Form.Item> */}

      <Form.Item 
        label="Birth date" 
        name="birthdayDate"
        rules={[
          { required: true, message: 'Enter date of birth!' }, // Removed whitespace rule, not needed for DatePicker
        ]}
      >
        <DatePicker 
          style={{ width: '100%' }} // To make the DatePicker take the full width
          format="YYYY-MM-DD" // Set the display format
          placeholder="Select birth date" // Placeholder text
        />
      </Form.Item>

      <Form.Item label="Country citizenship" name={"countryCitizenship"} 
        rules={[{
          required:true, 
          message:"Please enter your country"
        }]}
      >
        <Select>
          {allCountries.map((country) => (
            <Select.Option key={country} value={country}>
              {country}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Phone number" name={"phoneNumber"} 
        rules={[{
          required:true, 
          message:"Please enter your phone number!"
        }]}
      >
        <Input />
      </Form.Item>

      {/* <Form.Item label="É telemóvel" name="isMobile" valuePropName="checked">
        <Switch  checkedChildren="Sim" unCheckedChildren="Não"/>
      </Form.Item> */}

      <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
        NEXT
      </Button>
      <Link to="/login">
        <Button style={{ width: "100%" , background: "white",color:"Blue", marginTop: 30, marginBottom: 30}}  type="primary">
          CANCEL REGISTER
        </Button>
      </Link>
    </Form>)
  }

  function JobForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "100%"}} onFinish={onFinish} initialValues={initialValues}>
        <h4 align="center">Tell me more about your job</h4>
        <hr/>
        <br/>

        <h5 align="left">Are you employed?</h5>
        <br/>
        <Form.Item label="Professional situation" name={"employmentStatus"} 
          rules={[{
            required:true, 
            message:"Please enter employment status"
          }]}
        >
        <Select>
          <Select.Option value="Artisan">Artisan</Select.Option>
          <Select.Option value="Merchant">Merchant</Select.Option>
          <Select.Option value="Entrepreneur">Entrepreneur</Select.Option>
          <Select.Option value="Liberal profission">Liberal profission</Select.Option>
          <Select.Option value="Public service executive">Public service executive</Select.Option>
          <Select.Option value="Executive manager">Executive manager</Select.Option>
          <Select.Option value="Scientific profission">Scientific profission</Select.Option>
          <Select.Option value="Artistic profission">Artistic profission</Select.Option>
          <Select.Option value="Intermediate occupation">Intermediate occupation</Select.Option>
          <Select.Option value="Employee">Employee</Select.Option>
          <Select.Option value="Worker">Worker</Select.Option>
          <Select.Option value="Military">Military</Select.Option>
          <Select.Option value="Unemployee">Unemployee</Select.Option>
          <Select.Option value="Student">Student</Select.Option>
          <Select.Option value="Other">Other</Select.Option>
        </Select>
        </Form.Item>
      <hr/>

      <h5 align="left">Details of your profession</h5>
      <br/>
      <Form.Item label="Professional occupation" name={"ocuppationWork"} 
        rules={[{
          required:true, 
          message:"Please enter professional occupation!"
        }]}
      >
        <Select>
          <Select.Option value="Agriculture">Agriculture</Select.Option>
          <Select.Option value="Food">Food</Select.Option>
          <Select.Option value="Animals">Animals</Select.Option>
          <Select.Option value="Architecture - Crafts">Architecture - Crafts</Select.Option>
          <Select.Option value="Banking - Finance - Insurance">Banking - Finance - Insurance</Select.Option>
          <Select.Option value="Buildings - Public works">Buildings - Public works</Select.Option>
          <Select.Option value="Trade">Trade</Select.Option>
          <Select.Option value="Culture - Spectable">Culture - Spectable</Select.Option>
          <Select.Option value="Defense - Security - Relief">Defense - Security - Relief</Select.Option>
          <Select.Option value="Diamond dealers - Precious metals">Diamond dealers - Precious metals</Select.Option>
          <Select.Option value="Electronics - IT">Electronics - IT</Select.Option>
          <Select.Option value="Energy">Energy</Select.Option>
          <Select.Option value="Education - Training - Cleaning">Education - Training - Cleaning</Select.Option>
          <Select.Option value="Management - Audit - Human resources">Management - Audit - Human resources</Select.Option>
          <Select.Option value="Hotel and Catering">Hotel and Catering</Select.Option>
          <Select.Option value="Humanitarian">Humanitarian</Select.Option>
          <Select.Option value="Real Estate">Real Estate</Select.Option>
          <Select.Option value="Industry">Industry</Select.Option>
          <Select.Option value="Charm industry">Charm industry</Select.Option>
          <Select.Option value="Luxury industry">Luxury industry</Select.Option>
          <Select.Option value="Gambling - Casino">Gambling - Casino</Select.Option>
          <Select.Option value="Letters - Human sciences">Letters - Human sciences</Select.Option>
          <Select.Option value="Art market - Antique dealer">Art market - Antique dealer</Select.Option>
          <Select.Option value="Mechanical - Maintenance">Mechanical - Maintenance</Select.Option>
          <Select.Option value="Digital - Multimedia - Audiovisual">Digital - Multimedia - Audiovisual</Select.Option>
          <Select.Option value="Care - Aesthetics - Hairdressing">Care - Aesthetics - Hairdressing</Select.Option>
          <Select.Option value="Sport - Animation">Sport - Animation</Select.Option>
          <Select.Option value="Tourism">Tourism</Select.Option>
          <Select.Option value="Logistic transport">Logistic transport</Select.Option>
          <Select.Option value="Other sector">Other sector</Select.Option>
        </Select>
      </Form.Item>
   
      <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
        NEXT
      </Button>
      <Link to="/login">
        <Button style={{ width: "100%" , background: "white",color:"Blue", marginTop: 30, marginBottom: 30}}  type="primary">
          CANCEL REGISTER
        </Button>
      </Link>
    </Form>)
  }

  function ReviewForm({onFinish, initialValues}){
    return (
      <Form align="center" style={{ width: "100%"}} onFinish={onFinish} initialValues={initialValues}>
        <h4 align="center">Take a moment to review</h4>
        <h4 align="center"> and confirm your information</h4>
        <hr/>
        <br></br>
        <h5>Your personal information</h5>
        <br/>
        <p><b>Name:</b> {profileDetails.firstName} {profileDetails.lastName}</p>
        <br/>
        <p><b>Tax number:</b> {profileDetails.nif}</p>
        <br/>
        <p><b>Currency:</b> {profileDetails.currency}</p>
        <br/>
        <p><b>Date of birth:</b> {profileDetails.birthdayDate}</p>
        <br/>
        <p><b>Phone number:</b> {profileDetails.phoneNumber}</p>
        <br/>
        <p><b>Country Citizenship:</b> {profileDetails.countryCitizenship}</p>
        <hr/>
        <h5>Your employment information</h5>
        <br/>
        <p><b>Employment situation:</b> {jobDetails.employmentStatus}</p>
        <br/>
        <p ><b>Professional occupation:</b> {jobDetails.ocuppationWork}</p>
        <br/>
        <br/>
      <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
        NEXT
      </Button>
      <Link to="/login">
        <Button style={{ width: "100%" , background: "white",color:"Blue", marginTop: 30, marginBottom: 30}}  type="primary">
          CANCEL REGISTER
        </Button>
      </Link>
    </Form>)
  }

  function LoginForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "100%"}} onFinish={onFinish} initialValues={initialValues}>
    <h4 align="center">Configure the user and password</h4>
    <br/>
    <h6 align="center">Before logging into your account, create your username and password to securely access your online account.</h6>
    <hr/>
    <br/>
    <h6 align="left">Create a username and password</h6>
    <br/>
      <Form.Item style={{ width: "100%"}} label="Email" name={"email"} 
      rules={[{
        required:true,
        type:"email",
        message:"Please enter a valid email address!"
      }]}>
        <Input />
      </Form.Item>
      <Form.Item style={{ width: "100%"}} label="Password" name={"password"} 
      rules={[{
        required:true, 
        message:"Please enter your password!"
      }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item style={{ width: "100%"}}
        label="Confirm Password"
        name="confirmPassword"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: "Please check your keyword!"
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <br></br>
      <hr/>
      <h6 align="left">Associations</h6>
            <br/>
            <Form.Item>
              <h8 align="left">Is either a member of the immediate family who resides in the same household, is employed by, or is associated with, any of the following: a broker,
              stock exchange, exchange member company, securities brokerage or other financial institution?
              </h8>
            </Form.Item>
            <Form.Item label="" name="isPEP" valuePropName="checked">
              <Switch checkedChildren="YES" unCheckedChildren="NO" />
            </Form.Item>
            <Form.Item>
              <h8 align="left">The spouse or any relative living at home, of the agent (at the same address), member of the board of directors, 10% shareholder,
              a policy maker or someone who can direct the management and policies of a publicly traded company?
              </h8>
            </Form.Item>
            <Form.Item label="" name="isCorporateControl" valuePropName="checked">
            <Switch checkedChildren="YES" unCheckedChildren="NO" />
            </Form.Item>
      <br></br>
      <hr/>
      <h6 align="left">Privacy and Data Processing Policies</h6>
      <br/>
      <Form.Item label="I understand and accept the Privacy Policies" name="acceptPrivacy" valuePropName="checked"
          rules={[{
            required:true, 
            message:"Please indicate whether you accept the Privacy Policies"
          }]}>
        <Switch checkedChildren="Accepted" unCheckedChildren="I do not accept" />
      </Form.Item>
      <Form.Item label="I authorize the processing of personal data for the marketing of products and services" name="acceptMarketing" valuePropName="checked">
        <Switch  checkedChildren="Accepted" unCheckedChildren="I do not accept" />
      </Form.Item>
      <br></br>
      <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
        NEXT
      </Button>
      <Link to="/login">
        <Button style={{ width: "100%" , background: "white",color:"Blue", marginTop: 30, marginBottom: 30}}  type="primary">
          CANCEL REGISTER
        </Button>
      </Link>
    </Form>)
  }

  function AgenteForm({onFinish, initialValues}){
    return (
      <Form style={{ width: "100%"}} onFinish={onFinish} initialValues={initialValues}>
        <h4 align="center">Choose your Introducer Broker (IB)</h4>
        <hr/>
        <br/>

        <h5 align="left">Want to have an IB?</h5>
        <br/>
        <Form.Item label="" name={"agentName"} 
          rules={[{
            required:true, 
            message:"Please enter your Introducer Broker (IB)!"
          }]}
        >
        <Select
        >
            {
            allAgent.map((opts,i)=>
              <Select.Option key={i} value={opts._id}>
                {opts.agentCode}
              </Select.Option>)
            }
        </Select>
        </Form.Item>
      <hr/>

      <Button style={{ width: "100%" }} type="primary" htmlType='submit'>
        NEXT
      </Button>
      <Link to="/login">
        <Button style={{ width: "100%" , background: "white",color:"Blue", marginTop: 30, marginBottom: 30}}  type="primary">
          CANCEL REGISTER
        </Button>
      </Link>
    </Form>)
  }

  function FinishForm(){
    return (<>
        <div>
          <div className="success-container">
              <div className="success-symbol">
              <FaCheckCircle className="success-icon" size={100} color="#00b85c" />
              </div>
              <br></br>
              {/* <h1 style={{color:"green"}}>Registro bem-sucedido</h1> */}
              <p style={{color:"green"}}>Your registration was successfully completed!</p>
          </div>
          <Button style={{ width: "100%", marginTop:"20px" }}  type="primary" onClick={submitHandler}>
            GET STARTED NOW
          </Button>
          <Link to="/login">
          <Button style={{ width: "100%" , background: "white",color:"Blue", marginTop: 30, marginBottom: 30}}  type="primary">
            CANCEL REGISTER
          </Button>
      </Link>
        </div>
    </>)
  }

  // //Handler Test Console Log Values
  // const onFinish = (values) => {
  //   console.log({values});
  // };

  //***************************RENDER**************************************************************************/

  return (
      <>
      <div className="registration-container">
      {loading && <Spinner/>}
      {/* <div className="registration-image">
        <img src={bgImg} alt="Registration" />
      </div> */}
      <div className="registration-form">
        <h1>Register</h1>
        <Steps 
              style={{ padding: "32px 16px"}}
              onChange={setCurrent}
              current={current}
              progressDot={(dot, { status, index }) => (
                <span style={{ fontSize: '16px', width: '24px', height: '24px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#1890ff', color: '#fff' }}>{index + 1}</span>
              )}
              >
              <Steps.Step disabled={isStepDisabled(0)} title='Your Personal Profile' icon={<ProfileOutlined />} />
              <Steps.Step disabled={isStepDisabled(1)} title='Professional Situation' icon={<ShopOutlined />} />
              <Steps.Step disabled={isStepDisabled(2)} title='To review' icon={<LikeOutlined />} />
              <Steps.Step disabled={isStepDisabled(3)} title='Login' icon={<LoginOutlined />} />
              <Steps.Step disabled={isStepDisabled(4)} title='Your Introducer Broker (IB)' icon={<LoginOutlined />} />
              <Steps.Step disabled={isStepDisabled(5)} title='End' icon={<CheckCircleOutlined />} />
            </Steps>
        <div className="steps-action">
            {forms[current]}
        </div>
      </div>
    </div>

      </>
  );
};

export default RegisterAccountPage;