import React,{useState,useEffect} from "react";
import {Tabs, Form, Input, Button,Select, message} from 'antd';
import {useNavigate} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from 'axios';
import Spinner from "../components/Spinner";

const MyContacts = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();

  const { TabPane } = Tabs;
  const { TextArea } = Input;

  const user = JSON.parse(localStorage.getItem('user'));
  const investor = JSON.parse(localStorage.getItem('investorProfile'));

  const user_phoneNumber = user.phoneNumber;
  const user_agentName = user.agentName;
  const user_email = user.email;

  const [loading,setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState('mobile');

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleSubmitMobile = (values) => {
    // console.log('Submitted Mobile:', values);
    try{
    
      const user = JSON.parse(localStorage.getItem('user'))
  
      setLoading(true);
      const result = axios.post('/phonecontacts/add-phonecontact', {
        serviceRating: parseInt(values.serviceRating),
        agentName: user_agentName,
        userid:user._id, 
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Thank you, we will contact you as soon as possible!');
      setLoading(false);
      navigate('/')
    }catch (error){
      setLoading(false);
    }
  };

  const handleSubmitEmail = (values) => {
    // console.log('Submitted Email:', values);
    try{
    
      const user = JSON.parse(localStorage.getItem('user'))
  
      setLoading(true);
      const result = axios.post('/emailcontacts/add-emailcontact', {
        ...values,
        serviceRating: parseInt(values.serviceRating),
        agentName: user_agentName,
        userid:user._id,
      },
      {
        headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}`},
      }
      );
      setLoading(false);
      message.success('Thank you, we will contact you as soon as possible!');
      setLoading(false);
      navigate('/')
    }catch (error){
      setLoading(false);
    }
  };
  

  // const onFinish = (values) => {
  //   console.log({...values.serviceRating
  //   });
  // };

  return (
    <Layout>
      {loading && <Spinner/>}

      <div>
      <Tabs 
        defaultActiveKey="phoneNumber" 
        onChange={handleTabChange}
        type="card"
      >
        <TabPane tab="Phone Contact" key="mobile" style={{ marginTop:"25px"}} >
          <Form 
            layout="vertical"
            name="phoneNumberForm" 
            onFinish={handleSubmitMobile}
            >
            <Form.Item
              label={
                <div>
                  <h4>PHONE</h4>
                  <p>We will return the call to your number promptly:</p>
                </div>
              }
              name="phoneNumber"
            >
              <Input
              disabled
              defaultValue={user_phoneNumber}
              style={{ width: "100%", color:"black", textAlign: "left"}} 
              />
            </Form.Item>

            <Form.Item 
                label="Service Evaluation"
                name="serviceRating"
                rules={[
                  { required: true, message: 'Please rate the service!' }
                ]}
              >
                <div className="radio-group">
                  <label>
                    <input type="radio" name="serviceRating" value="1" />
                    <h2 className="emoji">😞</h2>
                  </label>
                  <label>
                    <input type="radio" name="serviceRating" value="2" />
                    <h2 className="emoji">😕</h2>
                  </label>
                  <label>
                    <input type="radio" name="serviceRating" value="3" />
                    <h2 className="emoji">😐</h2>
                  </label>
                  <label>
                    <input type="radio" name="serviceRating" value="4" />
                    <h2 className="emoji">🙂</h2>
                  </label>
                  <label>
                    <input type="radio" name="serviceRating" value="5" />
                    <h2 className="emoji">😊</h2>
                  </label>
                </div>
              </Form.Item>

            <Form.Item>
              <Button 
              style={{ width: "100%",marginTop: "20px"}} 
              type="primary" 
              htmlType="submit"
              >
                I WANT YOU TO CALL ME
              </Button>
              <Button
                style={{ width: "100%" , background: "white",color:"Blue", marginTop: 30, marginBottom: 30}}
                type="primary" 
                htmlType="submit"
                onClick={() => navigate('/')}
              >
                CANCEL
              </Button>
            </Form.Item>
          </Form>
        </TabPane>

        <TabPane tab="Email Contact" key="email" style={{ marginTop:"25px"}}>
        <Form 
            layout="vertical"
            name="phoneNumberForm" 
            onFinish={handleSubmitEmail}
            >
            <Form.Item
              label={
                <div>
                  <h4>EMAIL</h4>
                  <p>Use this form to express your doubts or request more information.</p>
                </div>
              }
              name="email"
            >
              <Input
              disabled
              defaultValue={user_email}
              style={{ width: "100%", color:"black", textAlign: "left"}} 
              />
            </Form.Item>

            <Form.Item 
              label="Subject" 
              name="subject"
              rules={[
                { required: true, message: 'Please select the subject!' }
              ]}
            >
              <Select>
                <Select.Option value="GENERAL">GENERAL</Select.Option>
                <Select.Option value="CAMPAIGNS">CAMPAIGNS</Select.Option>
                <Select.Option value="PERSONAL DATA">PERSONAL DATA</Select.Option>
                <Select.Option value="DOCUMENTS">DOCUMENTS</Select.Option>
                <Select.Option value="RECEIPT">RECEIPT</Select.Option>
                <Select.Option value="RECEIPTS FOR PAYMENT">RECEIPTS FOR PAYMENT</Select.Option>
                <Select.Option value="OTHERS">OTHERS</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item 
              label="" 
              name="message"
              rules={[
                { required: true, message: 'Please write the message!' }
              ]}
            >
              <TextArea
                  showCount
                  maxLength={500}
                  style={{
                    height: 125,
                    marginBottom: 12,
                  }}
                  type="text" 

                />
            </Form.Item>

            <Form.Item 
                label="Service Evaluation"
                name="serviceRating"
                rules={[
                  { required: true, message: 'Please rate the service!' }
                ]}
              >
                <div className="radio-group">
                  <label>
                    <input type="radio" name="serviceRating" value="1" />
                    <h2 className="emoji">😞</h2>
                  </label>
                  <label>
                    <input type="radio" name="serviceRating" value="2" />
                    <h2 className="emoji">😕</h2>
                  </label>
                  <label>
                    <input type="radio" name="serviceRating" value="3" />
                    <h2 className="emoji">😐</h2>
                  </label>
                  <label>
                    <input type="radio" name="serviceRating" value="4" />
                    <h2 className="emoji">🙂</h2>
                  </label>
                  <label>
                    <input type="radio" name="serviceRating" value="5" />
                    <h2 className="emoji">😊</h2>
                  </label>
                </div>
              </Form.Item>
              
            <Form.Item>
              <Button
                style={{ width: "100%", marginTop: "20px"}} 
                type="primary" 
                htmlType="submit"
              >
                SEND MESSAGE
              </Button>
              <Button
                style={{ width: "100%" , background: "white",color:"Blue", marginTop: 30, marginBottom: 30}}
                type="primary" 
                htmlType="submit"
                onClick={() => navigate('/')}
              >
                CANCEL
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
    </div>

    </Layout>
  );
};

export default MyContacts;